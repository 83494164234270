<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-blueGray-700 font-light mt-1 mx-5">
            Projects
          </h3>
        </div>
      </div>
    </div>
    <DataTable
      class=""
      :admin-prefix="adminPrefix"
      :api-prefix="apiPrefix"
      :singular-label="singularLabel"
      :plural-label="pluralLabel"
      :columns="columns"
      :test-boolean-key="testBooleanKey"
      :update-form="updateForm"
      :actions="actions"
      :nested-level="0"
    />
    <right-side-modal ref="schedulerModal" :showing="false">
      <template #header>
        <h4 class="text-xl font-light">Schedule Meeting</h4>
      </template>
      <template #body>
        <scheduler-modal
          v-if="schedulerHashedId"
          id-type="work"
          :hashed-id="schedulerHashedId"
        />
      </template>
    </right-side-modal>
  </div>
</template>
<script>
import { utils } from "common-frontend";
import DataTable from "./DataTable.vue";
import RightSideModal from "../Modals/RightSide.vue";
import SchedulerModal from "../Scheduler/SchedulerModal.vue";

const { avatarUrl } = utils;

export default {
  name: "Projects",
  components: {
    DataTable,
    RightSideModal,
    SchedulerModal,
  },
  data() {
    return {
      singularLabel: "project",
      pluralLabel: "projects",
      adminPrefix: "work",
      apiPrefix: "work",
      testBooleanKey: "collaborationSpace.isTestAccount",
      columns: {
        "collaborationSpace.isTestAccount": {
          type: "testBoolean",
          label: "Is Test Account",
          hidden: "never",
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "testBoolean",
            default: { label: "No", value: false },
          },
        },
        hashedId: {
          type: "formattedString",
          label: "Project ID",
          hidden: "yes",
          format(row) {
            const els = [];
            els.push(
              `<span class="text-xs uppercase font-mono text-nowrap">wk-${row.hashedId}</span>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "hashedId",
            prefix: "wk-",
          },
        },
        createdAt: {
          type: "absoluteDate",
          label: "Created At",
          sort: {
            allow: true,
            default: "desc",
          },
          filter: {
            allow: false,
            matchType: "dateRange",
            value: "",
            // config: https://chmln.github.io/flatpickr/options/
            config: {
              mode: "range",
              maxDate: "today",
            },
          },
        },
        "collaborationSpace.name": {
          type: "formattedString",
          label: "Collaboration Space",
          format(row) {
            const els = [];
            if (row.collaborationSpace) {
              els.push(
                `<span class="text-nowrap">${row.collaborationSpace.name}</span>`,
              );
              els.push(
                `<button class="text-xs bg-porcelain-300 px-2 py-1 ml-2 text-white rounded"><a href="/admin/collaboration-spaces/edit/${row.collaborationSpace.hashedId}" target="_blank"><i aria-hidden="true" class="fa-solid fa-arrow-up-right-from-square m-[2.5px]"></i></a></button>`,
              );
            }
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        title: {
          type: "formattedString",
          label: "Title",
          format(row) {
            const els = [];
            els.push(
              `<p class="max-w-[300px] overflow-ellipsis text-nowrap overflow-hidden">${row.title}</p>`,
            );
            if (row.parentWork?.title) {
              els.push(
                `<p class="text-sm opacity-50 max-w-[300px] overflow-ellipsis text-nowrap overflow-hidden">A milestone of ${row.parentWork?.title}</p>`,
              );
            }
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        status: {
          type: "enum",
          label: "Status",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
      },
      updateForm: {
        hashedId: {
          type: "hidden",
          value: "",
          default: "",
        },
        title: {
          label: "Title:",
          placeholder: "Enter title",
          type: "string",
          value: "",
          default: "",
          validatorTypes: ["required"],
        },
        description: {
          label: "Description:",
          placeholder: "Enter description",
          type: "text",
          value: "",
          default: "",
          validatorTypes: ["required"],
        },
        internalNotes: {
          label: "Internal Notes:",
          placeholder: "Enter internal notes",
          type: "text",
          value: "",
          default: "",
        },
        status: {
          label: "Status",
          placeholder: "Select status",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/WorkStatus",
          searchOnFocus: true,
        },
        people: {
          label: "People",
          type: "dataTable",
          value: "",
          singularLabel: "person",
          pluralLabel: "people",
          adminPrefix: "people",
          apiPrefix: "person",
          parentKey: "work_roles.work.hashed_id",
          createForm: {
            hashedId: {
              label: "Project Hashed ID:",
              default(row) {
                return row.hashedId;
              },
              type: "hidden",
              validatorTypes: ["required"],
            },
            person: {
              label:
                "Person (First, Last or Full Name / Primary Email Address)",
              placeholder: "Select person",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: ["required"],
              idKey: "hashedId",
              labelKey: "fullName",
              labelFunction(row) {
                return `${row.firstName} ${row.lastName} (${row.calc_EmailAddress}, per-${row.hashedId})`;
              },
              autocompleteEndpoint: "person",
              searchOnFocus: false,
            },
            role: {
              label: "Role",
              placeholder: "Select role",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: ["required"],
              idKey: "hashedId",
              labelKey: "name",
              autocompleteEndpoint: "role",
              filters: { role_type: "work" },
              searchOnFocus: true,
            },
          },
          updateForm: {},
          columns: {
            avatar: {
              type: "avatarWithActivityLevel",
              label: "Avatar",
              format(row) {
                return avatarUrl(null, row);
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
              },
            },
            firstName: {
              type: "string",
              label: "First Name",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            lastName: {
              type: "string",
              label: "Last Name",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            emailAddress: {
              type: "formattedString",
              label: "Primary Email Address",
              format(row) {
                const els = [];
                els.push(`${row.calc_EmailAddress}`);
                if (row.primaryEmailAddress.verifiedAt) {
                  els.push(`<i class="fas fa-check-square ml-2"></i>`);
                }
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            personType: {
              type: "enum",
              label: "Person Type",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          actions: {
            create: {
              allow: true,
              endpoint(hashedId) {
                return `work/${hashedId}/add-person/`;
              },
            },
            update: {
              allow: false,
            },
            delete: {
              allow: false,
            },
            linkToRecord: {
              allow: true,
              formatUrl(row) {
                return `profiles/edit/${row.profile.hashedId}`;
              },
            },
            menuItems: [
              {
                label: "Get Magic Link",
                qualifier() {
                  return true;
                },
                action(event, row) {
                  const url = `person/get-magic-link/`;
                  return [
                    "apiAction",
                    "postCopy",
                    url,
                    { emailAddress: row.calc_EmailAddress },
                  ];
                },
              },
            ],
          },
        },
        collaborationRequests: {
          label: "Collaboration Requests",
          type: "dataTable",
          value: "",
          singularLabel: "collaboration request",
          pluralLabel: "collaboration requests",
          adminPrefix: "collaboration-requests",
          apiPrefix: "work/request",
          parentKey: "collaborationSpace.hashed_id",
          createForm: {},
          columns: {
            hashedId: {
              type: "formattedString",
              label: "Collaboration Space ID",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">cr-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "grp-",
              },
            },
            createdAt: {
              type: "absoluteDate",
              label: "Created At",
              sort: {
                allow: true,
                default: "desc",
              },
              filter: {
                allow: true,
                matchType: "dateRange",
                value: "",
                // config: https://chmln.github.io/flatpickr/options/
                config: {
                  mode: "range",
                  maxDate: "today",
                },
              },
            },
            title: {
              type: "string",
              label: "Title",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            status: {
              type: "enum",
              label: "Status",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          updateForm: {
            hashedId: {
              type: "hidden",
              value: "",
              default: "",
            },
            title: {
              label: "Title:",
              placeholder: "Enter title",
              type: "string",
              value: "",
              default: "",
              validatorTypes: ["required"],
            },
            description: {
              label: "Description:",
              placeholder: "Enter description",
              type: "text",
              value: "",
              default: "",
              validatorTypes: ["required"],
            },
            status: {
              label: "Status",
              placeholder: "Select status",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/CollaborationRequestStatus",
              searchOnFocus: true,
            },
            isAnonymized: {
              label: "Hide Researcher Names:",
              checkboxLabel:
                "This collaboration request should hide names from the client.",
              type: "boolean",
              value: "",
              default: "",
            },
            deliverableType: {
              label: "Deliverable Type",
              placeholder: "Select deliverable type",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/DeliverableType",
              searchOnFocus: true,
            },
            engagementTimeline: {
              label: "Start Date",
              placeholder: "Select when to start",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/EngagementTimeline",
              searchOnFocus: true,
            },
            engagementDuration: {
              label: "Anticipated Duration",
              placeholder: "Select how long this project will last",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/EngagementDuration",
              searchOnFocus: true,
            },
            creditBudget: {
              label: "Credit Budget (x100):",
              placeholder: "Enter credit budget (times 100)",
              type: "string",
              value: "",
              default: "",
            },
          },
          actions: {
            update: {
              allow: true,
            },
            delete: {
              allow: false,
            },
            menuItems: [
              {
                label: "Search for Profiles",
                qualifier() {
                  return true;
                },
                action(event, row) {
                  const url = `${
                    import.meta.env.VITE_ADMIN_BASE_URL
                  }/admin/collaboration-requests/detail/${row.hashedId}`;
                  return ["visitUrl", "get", url];
                },
              },
            ],
          },
        },
      },
      actions: {
        update: {
          allow: true,
        },
        delete: {
          allow: false,
        },
        menuItems: [
          {
            label: "Schedule Meeting",
            qualifier(row) {
              return !row.parentWork?.hashedId;
            },
            action(event, row) {
              const self = this;
              return [
                "executeFunc",
                function _() {
                  self.vm.loadScheduler(row.hashedId);
                },
              ];
            },
            vm: this,
          },
        ],
      },
    };
  },
  methods: {
    loadScheduler(hashedId) {
      this.schedulerHashedId = hashedId;
      this.toggleSchedulerModal();
    },
    toggleSchedulerModal() {
      this.$refs.schedulerModal.toggleModal();
    },
  },
};
</script>
