<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-blueGray-700 font-light mt-1 mx-5">
            Invoices
          </h3>
        </div>
      </div>
    </div>
    <DataTable
      class=""
      :admin-prefix="adminPrefix"
      :api-prefix="apiPrefix"
      :singular-label="singularLabel"
      :plural-label="pluralLabel"
      :columns="columns"
      :test-boolean-key="testBooleanKey"
      :create-form="createForm"
      :update-form="updateForm"
      :actions="actions"
      :nested-level="0"
    />
    <right-side-modal ref="profileDetailModal" :showing="false">
      <template #header>
        <h4 class="text-xl font-light">Profile Detail</h4>
      </template>
      <template #body>
        <profile-detail-modal
          v-if="profileDetailHashedId"
          :hashed-id="profileDetailHashedId"
        />
      </template>
    </right-side-modal>
  </div>
</template>

<script>
import DataTable from "./DataTable.vue";
import RightSideModal from "../Modals/RightSide.vue";
import ProfileDetailModal from "../Profile/ProfileDetailModal.vue";

export default {
  name: "Invoices",
  components: {
    DataTable,
    RightSideModal,
    ProfileDetailModal,
  },
  data() {
    return {
      singularLabel: "invoice",
      pluralLabel: "invoices",
      adminPrefix: "invoices",
      apiPrefix: "invoice",
      testBooleanKey: "person.isTestAccount",
      columns: {
        "person.isTestAccount": {
          type: "testBoolean",
          label: "Is Test Account",
          hidden: "never",
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "testBoolean",
            default: { label: "No", value: false },
          },
        },
        hashedId: {
          type: "formattedString",
          label: "Invoice ID",
          hidden: "yes",
          format(row) {
            const els = [];
            els.push(
              `<span class="text-xs uppercase font-mono text-nowrap">ts-${row.hashedId}</span>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "hashedId",
            prefix: "TS-",
          },
        },
        createdAt: {
          type: "absoluteDate",
          label: "Created At",
          sort: {
            allow: true,
            default: "desc",
          },
          filter: {
            allow: false,
            matchType: "dateRange",
            value: "",
            // config: https://chmln.github.io/flatpickr/options/
            config: {
              mode: "range",
              maxDate: "today",
            },
          },
        },
        "person.fullName": {
          type: "formattedString",
          label: "Expert",
          format(row) {
            const els = [];
            els.push(
              `<span class="text-nowrap">${row.person?.profile?.fullDisplayName}</span>`,
            );
            els.push(
              `<button class="text-xs bg-porcelain-300 px-2 py-1 ml-2 text-white rounded"><a href="/admin/profiles/edit/${row.person?.profile?.hashedId}" target="_blank"><i aria-hidden="true" class="fa-solid fa-arrow-up-right-from-square m-[2.5px]"></i></a></button>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        dateRange: {
          type: "formattedString",
          label: "Date Range",
          format(row, vm) {
            const els = [];
            els.push(
              `<span class="text-nowrap">${vm
                .$dayjs(row?.periodStartAt)
                .format("MM/DD/YYYY")} &mdash; ${vm
                .$dayjs(row?.periodEndAt)
                .format("MM/DD/YYYY")}</span>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: false,
            default: null,
          },
          filter: {
            allow: false,
          },
        },
        totalAmount: {
          type: "formattedString",
          label: "Total Amount",
          format(row) {
            const els = [];
            // eslint-disable-next-line
            els.push(`<span>$${row?.calc_AmountCents / 100}</span>`); // eslint-disable-line
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: false,
            default: null,
          },
          filter: {
            allow: false,
          },
        },
        status: {
          type: "enum",
          label: "Invoice Status",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        paymentStatus: {
          type: "formattedString",
          label: "Payment Status",
          format(row) {
            const els = [];
            row?.payments.forEach((payment) => {
              els.push(
                `<div>${payment.status?.label} ($${
                  payment.calc_AmountCents / 100
                })</div>`,
              );
            });
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: false,
            default: null,
          },
          filter: {
            allow: false,
          },
        },
      },
      updateForm: {
        hashedId: {
          type: "hidden",
          value: "",
          default: "",
        },
        periodStartAt: {
          label: "Period Start At:",
          placeholder: "Enter date when this invoice period should start",
          type: "date",
          value: "",
          default: "",
        },
        periodEndAt: {
          label: "Period End At:",
          placeholder: "Enter date when this invoice period should end",
          type: "date",
          value: "",
          default: "",
        },
        internalNotes: {
          label: "Internal Notes:",
          placeholder: "Enter internal notes",
          type: "text",
          value: "",
          default: "",
        },
        person: {
          label: "Person",
          type: "parentDataTable",
          value: "",
          singularLabel: "person",
          pluralLabel: "people",
          apiPrefix: "person",
          childKey(row) {
            return row.person.hashedId;
          },
          createForm: {},
          updateForm: {},
          columns: {
            hashedId: {
              type: "formattedString",
              label: "Person ID",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">per-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "per-",
              },
            },
            firstName: {
              type: "string",
              label: "First Name",
              sort: {
                allow: false,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            lastName: {
              type: "string",
              label: "Last Name",
              sort: {
                allow: false,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            emailAddress: {
              type: "formattedString",
              label: "Primary Email Address",
              format(row) {
                const els = [];
                els.push(`${row.calc_EmailAddress}`);
                if (row.primaryEmailAddress.verifiedAt) {
                  els.push(`<i class="fas fa-check-square ml-2"></i>`);
                }
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          actions: {
            create: {
              allow: false,
            },
            update: {
              allow: false,
            },
            delete: {
              allow: false,
            },
            menuItems: [
              {
                label: "View Internal Profile",
                qualifier(row) {
                  return row.personType?.value === "EXPERT";
                },
                action(event, row) {
                  const self = this;
                  return [
                    "executeFunc",
                    function _() {
                      self.vm.loadProfileDetail(row.profile.hashedId);
                    },
                  ];
                },
                vm: this,
              },
              {
                label: "Get Magic Link",
                qualifier() {
                  return true;
                },
                action(event, row) {
                  const url = `person/get-magic-link/`;
                  return [
                    "apiAction",
                    "postCopy",
                    url,
                    { emailAddress: row.calc_EmailAddress },
                  ];
                },
              },
            ],
          },
        },
        entries: {
          label: "Line Items",
          type: "dataTable",
          value: "",
          singularLabel: "entry",
          pluralLabel: "entries",
          adminPrefix: "invoice-line-items",
          apiPrefix: "invoice-line-item",
          parentKey: "invoice.hashed_id",
          columns: {
            hashedId: {
              type: "formattedString",
              label: "Invoice Line Item ID",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">ili-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "ILI-",
              },
            },
            startAt: {
              type: "formattedString",
              label: "Date",
              format(row, vm) {
                const els = [];
                els.push(
                  `<span class="text-nowrap">${vm
                    .$dayjs(row?.startAt)
                    .format("MM/DD/YYYY")}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            notes: {
              type: "formattedString",
              label: "Hours Worked",
              format(row) {
                const els = [];
                els.push(
                  `<span>${row.minutesWorked / 60} hours @ $${
                    row.rateCents / 100
                  }/hour</span> ($${
                    ((row.minutesWorked / 60) * row.rateCents) / 100
                  })<br>`,
                );
                els.push(
                  `<span><em class="text-xs">Project:</em><br> ${row.work.title}</span><br>`,
                );
                els.push(
                  `<span><em class="text-xs">Collaboration Space:</em><br> ${row.work.collaborationSpace.name}</span><br>`,
                );
                els.push(`<span>${row.notes}</span><br>`);
                if (row.adminNotes) {
                  els.push(
                    `<span class="text-red-500">Admin note: ${row.adminNotes}</span><br>`,
                  );
                }
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            creditTransaction: {
              type: "formattedString",
              label: "Credit Transaction",
              format(row) {
                const els = [];
                if (row.collaborationSpaceCreditTransaction.hashedId) {
                  els.push(
                    `<span><em class="text-xs">Client Description:</em><br> ${row.collaborationSpaceCreditTransaction.clientDescription}<br></span>`,
                  );
                  els.push(
                    `<span><em class="text-xs">Internal Notes:</em><br> ${row.collaborationSpaceCreditTransaction.internalNotes}<br></span>`,
                  );
                  els.push(
                    `<span><em class="text-xs">Credit Total:</em><br> ${
                      row.collaborationSpaceCreditTransaction.quantity / 100
                    }<br>
                    <em class="text-xs" style="line-height: .75rem">
                      Note: Credit Total applies across all<br>
                      line items tied to this transaction.<br>
                      Do not use this for calculating margin.<br>
                      Use the <a target="_blank" style="color: blue; text-decoration:underline" href="/admin/finance">finance view</a> instead.
                    </em></span>`,
                  );
                } else {
                  els.push(
                    `<span><em class="text-xs">Not Connected</em></span>`,
                  );
                }
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          createForm: {
            invoiceId: {
              label: "Invoice ID:",
              default(row) {
                return row.hashedId;
              },
              type: "hidden",
              validatorTypes: ["required"],
            },
            work: {
              label: "Project ID",
              placeholder: "Select project",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpointFunction(row) {
                return `work/for-person/${row.person.hashedId}/autocomplete`;
              },
              searchOnFocus: true,
            },
            startAt: {
              label: "Start At:",
              placeholder: "Enter date when this invoice line item starts",
              type: "date",
              value: "",
              default: "",
            },
            minutesWorked: {
              label: "Time Worked (in minutes):",
              placeholder: "Enter the time in minutes",
              type: "integer",
              value: "",
              default: "",
            },
            rateCents: {
              label: "Hourly Rate (in cents):",
              placeholder: "Enter the hourly rate in cents",
              type: "integer",
              value: "",
              default: "",
            },
            notes: {
              label: "Notes:",
              placeholder: "Enter notes",
              type: "text",
              value: "",
              default: "",
            },
            adminNotes: {
              label: "Admin Notes:",
              placeholder: "Enter notes",
              type: "text",
              value: "",
              default: "",
            },
          },
          updateForm: {
            hashedId: {
              type: "hidden",
              value: "",
              default: "",
            },
            collaborationSpaceCreditTransaction: {
              label: "Collaboration Space Credit Transaction",
              placeholder: "Select collaboration space credit transaction",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "hashedId",
              labelKey: "clientDescription",
              autocompleteEndpointFunction(row) {
                return `collaboration-space-credit-transaction/for-collaboration-space/${row.work.collaborationSpace.hashedId}/autocomplete`;
              },
              searchOnFocus: true,
            },
            startAt: {
              label: "Start At:",
              placeholder: "Enter date when this invoice line item starts",
              type: "date",
              value: "",
              default: "",
            },
            minutesWorked: {
              label: "Time Worked (in minutes):",
              placeholder: "Enter the time in minutes",
              type: "integer",
              value: "",
              default: "",
            },
            rateCents: {
              label: "Hourly Rate (in cents):",
              placeholder: "Enter the hourly rate in cents",
              type: "integer",
              value: "",
              default: "",
            },
            notes: {
              label: "Notes:",
              placeholder: "Enter notes",
              type: "text",
              value: "",
              default: "",
            },
            adminNotes: {
              label: "Admin Notes:",
              placeholder: "Enter notes",
              type: "text",
              value: "",
              default: "",
            },
          },
          actions: {
            create: {
              allow: true,
            },
            update: {
              allow: true,
            },
            delete: {
              allow: true,
            },
            menuItems: [],
          },
        },
        payments: {
          label: "Payments",
          type: "dataTable",
          value: "",
          singularLabel: "payment",
          pluralLabel: "payment",
          adminPrefix: "payments",
          apiPrefix: "payment",
          parentKey: "invoice.hashed_id",
          createForm: {},
          columns: {
            createdAt: {
              type: "formattedString",
              label: "Date",
              format(row, vm) {
                const els = [];
                els.push(
                  `<span class="text-nowrap">${vm
                    .$dayjs(row?.createdAt)
                    .format("MM/DD/YYYY")}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            amount: {
              type: "formattedString",
              label: "Amount",
              format(row) {
                const els = [];
                els.push(`<span>$${row.calc_AmountCents / 100}`);
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            status: {
              type: "enum",
              label: "Status",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          updateForm: {
            hashedId: {
              type: "hidden",
              value: "",
              default: "",
            },
            calc_AmountCents: {
              label: "Amount (in cents):",
              placeholder: "Enter the amount of this payment (in cents)",
              type: "integer",
              value: "",
              default: "",
            },
            status: {
              label: "Status",
              placeholder: "Select status",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/PaymentStatus",
              searchOnFocus: true,
            },
          },
          actions: {
            create: {
              allow: false,
            },
            update: {
              allow: true,
            },
            delete: {
              allow: true,
            },
            menuItems: [],
          },
        },
        revisions: {
          type: "revisionHistory",
          modelClassName: "invoice",
          childKeys: [
            {
              modelClassName: "invoice_line_item",
              relationship: "lineItems",
            },
            {
              modelClassName: "payment",
              relationship: "payments",
            },
          ],
          value: "",
        },
      },
      createForm: {
        person: {
          label: "Person (First, Last or Full Name) / Primary Email Address",
          placeholder: "Select person",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: ["required"],
          idKey: "hashedId",
          labelKey: "fullName",
          labelFunction(row) {
            return `${row.firstName} ${row.lastName} (${row.calc_EmailAddress}, per-${row.hashedId})`;
          },
          autocompleteEndpoint: "person",
          searchOnFocus: false,
        },
        periodStartAt: {
          label: "Period Start At:",
          placeholder: "Enter date when this invoice period should start",
          type: "date",
          value: "",
          default: "",
        },
        periodEndAt: {
          label: "Period End At:",
          placeholder: "Enter date when this invoice period should end",
          type: "date",
          value: "",
          default: "",
        },
        internalNotes: {
          label: "Internal Notes:",
          placeholder: "Enter internal notes",
          type: "text",
          value: "",
          default: "",
        },
      },
      actions: {
        create: {
          allow: true,
        },
        update: {
          allow: true,
        },
        delete: {
          allow: false,
        },
        menuItems: [
          {
            label: "Approve",
            qualifier(row) {
              return row.status.value === "IN_REVIEW";
            },
            action(event, row) {
              const url = `invoice/${row.hashedId}/approve/`;
              return ["apiAction", "putReload", url, {}];
            },
          },
          {
            label: "Reject",
            qualifier(row) {
              return row.status.value === "IN_REVIEW";
            },
            action(event, row) {
              const url = `invoice/${row.hashedId}/reject/`;
              return ["apiAction", "putReload", url, {}];
            },
          },
          {
            label: "Submit for Review",
            qualifier(row) {
              return row.status.value === "DRAFT";
            },
            action(event, row) {
              const url = `invoice/${row.hashedId}/submit/`;
              return ["apiAction", "putReload", url, {}];
            },
          },
          {
            label: "Reset to In Review",
            qualifier(row) {
              return (
                row.status.value === "APPROVED" ||
                row.status.value === "REJECTED"
              );
            },
            action(event, row) {
              const url = `invoice/${row.hashedId}/reset/`;
              return ["apiAction", "putReload", url, {}];
            },
          },
          {
            label: "Copy Time Summary for Client",
            qualifier() {
              return true;
            },
            action(event, row) {
              const url = `invoice/${row.hashedId}/summary/client/`;
              return ["apiAction", "getCopy", url, {}];
            },
          },
          {
            label: "Copy Time Summary for Internal Records",
            qualifier() {
              return true;
            },
            action(event, row) {
              const url = `invoice/${row.hashedId}/summary/internal/`;
              return ["apiAction", "getCopy", url, {}];
            },
          },
          {
            label: "Create Payment Object",
            qualifier(row) {
              return row.status.value === "APPROVED";
            },
            action(event, row) {
              const url = `payment/create-from-invoice/`;
              return [
                "apiAction",
                "postReload",
                url,
                { invoiceId: row.hashedId },
              ];
            },
          },
          {
            label: "Delete Invoice",
            qualifier() {
              return true;
            },
            confirmFirst: true,
            confirmationMessage:
              "Are you sure you want to delete this invoice?",
            action(event, row) {
              const url = `invoice/${row.hashedId}/`;
              return [
                "apiAction",
                "deleteReload",
                url,
                { invoiceId: row.hashedId },
              ];
            },
          },
        ],
      },
    };
  },
  methods: {
    loadProfileDetail(hashedId) {
      this.profileDetailHashedId = hashedId;
      this.toggleProfileDetailModal();
    },
    toggleProfileDetailModal() {
      this.$refs.profileDetailModal.toggleModal();
    },
  },
};
</script>
