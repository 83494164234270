<template>
  <tr>
    <td class="border px-2 py-1 align-top w-[100px]">
      {{ $dayjs(transaction.clientReflectedAt).format("MM/DD/YYYY") }}
    </td>
    <td class="border px-2 py-1 align-top w-[140px]">
      <span
        class="text-sm rounded px-1 uppercase font-bold bg-porcelain-400 text-white"
        >{{ transaction.transactionType[0] }}</span
      >
    </td>
    <td class="border align-top w-[120px]">
      <template
        v-if="
          transaction.transactionType[0] == 'Debit' ||
          transaction.transactionType[0] == 'Transfer (out)'
        "
      >
        <div class="px-2 py-1">
          ({{ USDollar.format(transaction.fulfillmentCostPerCredit) }})
        </div>
        <div
          class="px-3 pt-2"
          :class="{
            'bg-red-500/50':
              transaction.averageCostPerCredit -
                transaction.fulfillmentCostPerCredit <
              0,
            'bg-green-500/50':
              transaction.averageCostPerCredit -
                transaction.fulfillmentCostPerCredit >=
                0 &&
              (
                ((transaction.averageCostPerCredit -
                  transaction.fulfillmentCostPerCredit) /
                  transaction.averageCostPerCredit) *
                100
              ).toFixed(2) >= 50,
            'bg-yellow-500/50':
              transaction.averageCostPerCredit -
                transaction.fulfillmentCostPerCredit >=
                0 &&
              (
                ((transaction.averageCostPerCredit -
                  transaction.fulfillmentCostPerCredit) /
                  transaction.averageCostPerCredit) *
                100
              ).toFixed(2) < 50,
          }"
        >
          <p class="text-xs uppercase font-bold">Margin / Credit</p>
          {{
            USDollar.format(
              transaction.averageCostPerCredit -
                transaction.fulfillmentCostPerCredit,
            )
          }}<br />
          {{
            (
              ((transaction.averageCostPerCredit -
                transaction.fulfillmentCostPerCredit) /
                transaction.averageCostPerCredit) *
              100
            ).toFixed(2)
          }}%
        </div>
      </template>
      <template v-else>
        <div class="px-2 py-1">
          {{ USDollar.format(transaction.costPerCredit) }}
        </div>
      </template>
    </td>
    <td class="border px-2 py-1 align-top w-[80px]">
      <span v-if="transaction.transactionType[0] == 'Debit'"
        >({{ transaction.quantity }})</span
      >
      <span v-else>{{ transaction.quantity }}</span>
    </td>
    <td class="border p-0 align-top">
      <div v-if="transaction.clientDescription" class="px-2 py-1">
        <p class="uppercase font-bold text-xs text-porcelain-400">
          Client Description
        </p>
        {{ transaction.clientDescription }}
      </div>
      <div v-if="transaction.internalNotes" class="px-2 py-1">
        <p class="uppercase font-bold text-xs text-porcelain-400">
          Internal Notes
        </p>
        {{ transaction.internalNotes }}
      </div>
      <div v-if="transaction.nonInvoiceFulfillmentCostCents" class="px-2 py-1">
        <p class="uppercase font-bold text-xs text-porcelain-400">
          Non-Invoice Fulfillment Cost
        </p>
        {{ USDollar.format(transaction.nonInvoiceFulfillmentCostCents / 100) }}
      </div>
      <div v-if="transaction.invoiceLineItems?.length > 0">
        <p class="uppercase font-bold text-xs text-porcelain-400 px-2 py-1">
          <button @click="toggleExpanded()">
            <i
              :class="{ 'rotate-90': lineItemsExpanded }"
              class="text-xs fa-solid fa-caret-right"
            ></i></button
          ><button class="uppercase" @click="toggleExpanded()">
            &nbsp;&nbsp;Invoice Line Items ({{
              transaction.invoiceLineItems.length
            }})
          </button>
        </p>
        <table v-if="lineItemsExpanded" class="w-full m-0 text-sm">
          <thead class="bg-porcelain">
            <tr>
              <th
                class="uppercase text-xs text-left border px-2 py-1 text-porcelain-400 w-[80px]"
              >
                Date
              </th>
              <th
                class="uppercase text-xs text-left border px-2 py-1 text-porcelain-400 w-[100px]"
              >
                Invoice Status
              </th>
              <th
                class="uppercase text-xs text-left border px-2 py-1 text-porcelain-400 w-[80px]"
              >
                Amount
              </th>
              <th
                class="uppercase text-xs text-left border px-2 py-1 text-porcelain-400 w-[160px]"
              >
                Person
              </th>
              <th
                class="uppercase text-xs text-left border px-2 py-1 text-porcelain-400"
              >
                Detail
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="lineItem in transaction.invoiceLineItems"
              :key="lineItem.hashedId"
            >
              <td class="border px-2 py-1 align-top">
                {{ $dayjs(lineItem.date).format("MM/DD/YYYY") }}
              </td>
              <td class="border px-2 py-1 align-top">
                {{ lineItem.invoiceStatus[0] }}
              </td>
              <td class="border px-2 py-1 align-top">
                {{ USDollar.format(lineItem.amount) }}
              </td>
              <td class="border px-2 py-1 align-top">
                {{ lineItem.personFullName }}
              </td>
              <td class="border px-2 py-1 align-top">
                <p class="uppercase font-bold text-xs text-porcelain-400">
                  Project
                </p>
                {{ lineItem.workTitle }}
                <p
                  v-if="lineItem.notes"
                  class="uppercase font-bold text-xs text-porcelain-400"
                >
                  Client Notes
                </p>
                {{ lineItem.notes }}
                <p
                  v-if="lineItem.clientNotes"
                  class="uppercase font-bold text-xs text-porcelain-400"
                >
                  Admin Notes
                </p>
                {{ lineItem.adminNotes }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="
          transaction.transactionType[0] == 'Debit' &&
          transaction.invoiceLineItems.length === 0 &&
          transaction.nonInvoiceFulfillmentCostCents === null
        "
        class="bg-orange-100 px-2 py-1"
      >
        <em
          >There are no line items or fulfillment costs associated with this
          debit.</em
        >
      </div>
    </td>
    <td class="border px-2 py-1 align-top w-[80px]">
      {{ transaction.runningBalance }}
    </td>
  </tr>
</template>

<script>
export default {
  name: "AdminFinanceTransactionRow",
  components: {},
  props: {
    transaction: Object,
  },
  data() {
    return {
      lineItemsExpanded: false,
      USDollar: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }),
    };
  },
  methods: {
    toggleExpanded() {
      this.lineItemsExpanded = !this.lineItemsExpanded;
    },
  },
};
</script>
