<template>
  <div class="max-w-full overflow-x-auto px-5">
    <div class="flex flex-wrap mb-2">
      <div class="w-full md:w-8/12">
        <p class="text-sm text-black/70" v-html="tableCursor"></p>
      </div>
    </div>
    <div class="block w-full">
      <div class="w-full flex justify-between">
        <div class="flex">
          <button
            class="border border-solid border-porcelain-300 px-2 py-1 text-black/70 rounded-t-md border-b-0"
            :class="{
              'bg-porcelain-200 text-white': showColumnSelector,
              'text-black/70': !showColumnSelector,
            }"
            @click.prevent="showColumnSelector = true"
          >
            <i class="fa-regular fa-rectangle-list"></i>
          </button>
          <div
            v-if="testBooleanKey"
            class="border border-solid border-porcelain-300 px-2 py-1 text-black/70 rounded-t-md border-b-0 mx-2"
          >
            <span class="uppercase text-sm mr-2 block md:inline"
              >Account&nbsp;Type:</span
            >
            <a
              href="#"
              class="text-xs px-2 rounded-l bg-orange-400 text-white"
              :class="{
                'opacity-50': dataTable.filters[testBooleanKey]?.value != true,
              }"
              @click="
                dataTable.filters[testBooleanKey] = {
                  label: 'Yes',
                  value: true,
                };
                invokeFilter(name);
              "
              >TEST</a
            >
            <a
              href="#"
              class="text-xs px-2 bg-black/50 text-white"
              :class="{
                'opacity-50':
                  Object.keys(dataTable.filters).indexOf(testBooleanKey) > -1,
              }"
              @click="
                delete dataTable.filters[testBooleanKey];
                invokeFilter(name);
              "
              >ALL</a
            >
            <a
              href="#"
              class="text-xs px-2 rounded-r bg-green-600 text-white"
              :class="{
                'opacity-50': dataTable.filters[testBooleanKey]?.value != false,
              }"
              @click="
                dataTable.filters[testBooleanKey] = {
                  label: 'No',
                  value: false,
                };
                invokeFilter(name);
              "
              >REAL</a
            >
          </div>
        </div>
        <div class="flex">
          <button
            v-if="createForm && Object.keys(createForm).length > 0"
            class="border border-solid border-porcelain-300 px-2 py-1 ml-2 text-black/70 rounded-t-md border-b-0"
            @click.prevent="openCreateForm(true)"
          >
            <i aria-hidden="true" class="fa fa-plus" />
            <span class="sr-only">Add</span>
          </button>
          <button
            class="border border-solid border-porcelain-300 px-2 py-1 ml-2 text-black/70 rounded-t-md border-b-0"
            @click.prevent="refresh()"
          >
            <i aria-hidden="true" class="fas fa-sync-alt" />
            <span class="sr-only">Refresh</span>
          </button>
        </div>
      </div>
      <div
        v-if="showColumnSelector"
        v-click-outside="
          function () {
            showColumnSelector = false;
          }
        "
        class="absolute bg-porcelain-200 p-4 border border-porcelain-300 rounded-lg z-10 max-w-[500px]"
      >
        <p class="form-help">Select the columns to include in this view.</p>
        <div class="flex flex-wrap">
          <div
            v-for="(metadata, name) in columns"
            :key="name"
            class="w-full sm:w-1/2 md:w-1/4 min-w-[200px]"
          >
            <label
              class="cursor-pointer"
              :class="{ 'opacity-50': metadata.hidden == 'never' }"
            >
              <input
                v-model="shownColumns"
                type="checkbox"
                :disabled="metadata.hidden == 'never'"
                :value="name"
              />
              {{ metadata.label }}
            </label>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto">
        <table
          class="items-center border-collapse border-2 border-solid border-porcelain-300 bg-white/50 h-full min-w-full"
        >
          <thead class="bg-porcelain-200/50">
            <tr>
              <th
                v-for="(metadata, name) in columns"
                :key="name"
                :class="{ hidden: shownColumns.indexOf(name) === -1 }"
                class="px-4 align-middle pt-2 whitespace-nowrap text-left text-black/70 font-normal"
                scope="col"
              >
                <div
                  v-if="name != testBooleanKey"
                  class="flex justify-between text-sm"
                >
                  <span class="uppercase">{{ metadata.label }}</span>
                  <template v-if="metadata.sort.allow">
                    <span
                      v-if="Object.keys(tableSortKeys).indexOf(name) > -1"
                      class="ml-3 opacity-80"
                      @click.prevent="invokeSort(name)"
                    >
                      <span v-if="tableSortKeys[name] == 'asc'">
                        <i aria-hidden="true" class="fas fa-sort-up" />
                        <span class="sr-only">Sort Descending</span>
                      </span>
                      <span v-if="tableSortKeys[name] == 'desc'">
                        <i aria-hidden="true" class="fas fa-sort-down" />
                        <span class="sr-only">Sort Ascending</span>
                      </span>
                    </span>
                    <span
                      v-if="Object.keys(tableSortKeys).indexOf(name) == -1"
                      class="ml-3 opacity-80"
                      @click.prevent="invokeSort(name)"
                    >
                      <i aria-hidden="true" class="fas fa-sort text-muted" />
                      <span class="sr-only">Sort</span>
                    </span>
                  </template>
                </div>
              </th>
              <th
                v-if="
                  (actions.linkToRecord && actions.linkToRecord.allow) ||
                  (actions.viewDetail && actions.viewDetail.allow) ||
                  (actions.update && actions.update.allow) ||
                  (actions.delete && actions.delete.allow) ||
                  (actions.menuItems && actions.menuItems.length > 0)
                "
                class="px-4 align-middle py-3 text-xs uppercase whitespace-nowrap font-semibold text-left text-black/70"
              />
            </tr>
            <tr class="border-0">
              <th
                v-for="(metadata, name) in columns"
                :key="name"
                :class="{ hidden: shownColumns.indexOf(name) === -1 }"
                scope="col"
              >
                <div v-if="metadata.filter.allow && name != testBooleanKey">
                  <div class="flex px-4 pb-2 items-center">
                    <div v-if="dataTable.filters[name]" class="pr-2">
                      <a
                        href="#"
                        role="button"
                        aria-label="Remove"
                        @click.prevent="clearFilter(dataTable.filters, name)"
                      >
                        <i aria-hidden="true" class="fa fa-times" />
                        <span class="sr-only">Clear Filters</span>
                      </a>
                    </div>
                    <template v-if="metadata.filter.matchType == 'wildcard'">
                      <input
                        :id="'filter-' + apiPrefix + '-' + name + '-input'"
                        v-model="dataTable.filters[name]"
                        type="text"
                        class="border-0 px-3 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-chartreuseYellow w-full ease-linear transition-all duration-150"
                        placeholder="Filter Text"
                        @input="invokeFilter(name)"
                      />
                    </template>
                    <template v-if="metadata.filter.matchType == 'boolean'">
                      <v-select
                        :id="'filter-' + apiPrefix + '-' + name + '-input'"
                        v-model="dataTable.filters[name]"
                        class="text-sm bg-white shadow"
                        :clearable="false"
                        :options="[
                          { label: 'Yes', value: true },
                          { label: 'No', value: false },
                        ]"
                        :search-on-focus="false"
                        :multiple="false"
                        @option:selected="invokeFilter(name)"
                      />
                    </template>
                    <template v-if="metadata.filter.matchType == 'hashedId'">
                      <span class="font-normal text-xs uppercase font-mono">{{
                        metadata.filter.prefix
                      }}</span>
                      <input
                        :id="'filter-' + apiPrefix + '-' + name + '-input'"
                        v-model="dataTable.filters[name]"
                        type="text"
                        class="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-chartreuseYellow w-full ease-linear transition-all duration-150"
                        placeholder="ID"
                        @input="invokeFilter(name)"
                      />
                    </template>
                    <template v-if="metadata.filter.matchType == 'dateRange'">
                      <datepicker
                        :id="'filter-' + apiPrefix + '-' + name + '-input'"
                        v-model="dataTable.filters[name]"
                        placeholder="Filter Text"
                        input-class="!py-1"
                        @update="dataTable.filters[name] = $event"
                      ></datepicker>
                    </template>
                  </div>
                </div>
              </th>
              <th
                v-if="
                  (actions.linkToRecord && actions.linkToRecord.allow) ||
                  (actions.viewDetail && actions.viewDetail.allow) ||
                  (actions.update && actions.update.allow) ||
                  (actions.delete && actions.delete.allow) ||
                  (actions.menuItems && actions.menuItems.length > 0)
                "
              />
            </tr>
          </thead>
          <tbody v-if="!dataTable.loading">
            <tr
              v-for="(row, index) in dataTable.results"
              :key="index"
              class="h-full border border-solid border-y-porcelain-200 border-x-porcelain-300 last:border-b-porcelain-300"
              :class="{
                'bg-orange-100':
                  testBooleanKey && testBooleanKey.split('.').length == 2
                    ? row[testBooleanKey.split('.')[0]][
                        testBooleanKey.split('.')[1]
                      ] == true
                    : row[testBooleanKey] == true,
              }"
            >
              <td
                v-for="(metadata, name) in columns"
                :key="name"
                class="h-full border-t-0 align-middle border-l-0 border-r-0 whitespace-nowrap"
                :class="{
                  'py-2 px-4': metadata.type != 'testBoolean',
                  hidden: shownColumns.indexOf(name) === -1,
                }"
              >
                <template v-if="metadata.type == 'string'">
                  <span v-if="name.split('.').length == 2">
                    {{ row[name.split(".")[0]][name.split(".")[1]] }}
                  </span>
                  <span v-else>
                    {{ row[name] }}
                  </span>
                </template>
                <template v-if="metadata.type == 'boolean'">
                  <span v-if="name.split('.').length == 2">
                    {{
                      row[name.split(".")[0]][name.split(".")[1]] ? "Yes" : "No"
                    }}
                  </span>
                  <span v-else>
                    {{ row[name] ? "Yes" : "No" }}
                  </span>
                </template>
                <template v-if="metadata.type == 'testBoolean'">
                  <div
                    class="h-full text-white flex items-center rounded w-4 text-xs"
                    :class="{
                      'bg-orange-400':
                        name.split('.').length > 1
                          ? row[name.split('.')[0]][name.split('.')[1]]
                          : row[name],
                      'opacity-0':
                        name.split('.').length > 1
                          ? !row[name.split('.')[0]][name.split('.')[1]]
                          : !row[name],
                    }"
                  >
                    <span
                      v-if="name.split('.').length == 2"
                      class="rotate-180"
                      style="
                        writing-mode: vertical-rl;
                        text-orientation: sideways-right;
                      "
                    >
                      {{
                        row[name.split(".")[0]][name.split(".")[1]]
                          ? "TEST"
                          : "REAL"
                      }}
                    </span>
                    <span
                      v-else
                      class="rotate-180"
                      style="
                        writing-mode: vertical-rl;
                        text-orientation: sideways-right;
                      "
                    >
                      {{ row[name] ? "TEST" : "REAL" }}
                    </span>
                  </div>
                </template>
                <template v-if="metadata.type == 'integer'">
                  {{ row[name] }}
                </template>
                <template v-if="metadata.type == 'enum'">
                  <span v-if="name.split('.').length == 2">
                    {{ row[name.split(".")[0]][name.split(".")[1]]?.label }}
                  </span>
                  <span v-else>
                    {{ row[name]?.label }}
                  </span>
                </template>
                <span
                  v-if="metadata.type == 'monospace'"
                  class="text-xs uppercase font-mono"
                >
                  {{ row[name] }}
                </span>
                <span v-if="metadata.type == 'avatar'">
                  <profile-image
                    v-if="metadata.format(row, this)"
                    :size="40"
                    :img-url="metadata.format(row, this)"
                  />
                </span>
                <span v-if="metadata.type == 'avatarWithActivityLevel'">
                  <div
                    v-if="
                      row.calc_ActivityLevel ||
                      (row.person && row.person.calc_ActivityLevel)
                    "
                    class="relative -mb-[20px] -mr-[10px]"
                  >
                    <context-popper
                      :text="
                        row.calc_ActivityLevel?.description ||
                        row.person.calc_ActivityLevel.description
                      "
                    >
                      <div
                        v-if="
                          (row.calc_ActivityLevel?.value ||
                            row.person.calc_ActivityLevel.value) ==
                          'VERY_ACTIVE'
                        "
                        class="border border-white bg-christi -mt-6 w-3 h-3 rounded-full"
                      >
                        &nbsp;
                      </div>
                      <div
                        v-if="
                          (row.calc_ActivityLevel?.value ||
                            row.person.calc_ActivityLevel.value) == 'ACTIVE'
                        "
                        class="border border-white bg-inch-worm -mt-6 w-3 h-3 rounded-full"
                      >
                        &nbsp;
                      </div>
                      <div
                        v-if="
                          (row.calc_ActivityLevel?.value ||
                            row.person.calc_ActivityLevel.value) == 'DECLINE'
                        "
                        class="border border-white bg-lemon -mt-6 w-3 h-3 rounded-full"
                      >
                        &nbsp;
                      </div>
                      <div
                        v-if="
                          (row.calc_ActivityLevel?.value ||
                            row.person.calc_ActivityLevel.value) == 'INACTIVE'
                        "
                        class="border border-white bg-black-200 -mt-6 w-3 h-3 rounded-full"
                      >
                        &nbsp;
                      </div>
                      <div
                        v-if="
                          (row.calc_ActivityLevel?.value ||
                            row.person.calc_ActivityLevel.value) == 'ERROR'
                        "
                        class="border border-white bg-guardsman-red -mt-6 w-3 h-3 rounded-full"
                      >
                        &nbsp;
                      </div>
                    </context-popper>
                  </div>
                  <profile-image
                    v-if="metadata.format(row, this)"
                    :size="40"
                    :img-url="metadata.format(row, this)"
                  />
                </span>
                <span v-if="metadata.type == 'avatarStack'">
                  <div class="flex">
                    <!-- eslint-disable-next-line -->
                    <template v-for="item in metadata.format(row, this)">
                      <!-- eslint-disable-next-line -->
                      <context-popper :text="item.title">
                        <a :href="item.href" target="_blank">
                          <profile-image
                            v-if="item.src"
                            :size="40"
                            :img-url="item.src"
                            class="-mr-2"
                          />
                        </a>
                      </context-popper>
                    </template>
                  </div>
                </span>
                <span v-if="metadata.type == 'avatarStackWithActivityLevel'">
                  <div class="flex">
                    <!-- eslint-disable-next-line -->
                    <template v-for="item in metadata.format(row, this)">
                      <!-- eslint-disable-next-line -->
                      <div class="relative -mb-[20px] -mr-[10px]" v-if="item.activityLevel">
                        <context-popper
                          v-if="item.activityLevel"
                          :text="item.activityLevel.description"
                        >
                          <div
                            v-if="item.activityLevel.value == 'VERY_ACTIVE'"
                            class="border border-white bg-christi -mt-6 w-3 h-3 rounded-full"
                          >
                            &nbsp;
                          </div>
                          <div
                            v-if="item.activityLevel.value == 'ACTIVE'"
                            class="border border-white bg-inch-worm -mt-6 w-3 h-3 rounded-full"
                          >
                            &nbsp;
                          </div>
                          <div
                            v-if="item.activityLevel.value == 'DECLINE'"
                            class="border border-white bg-lemon -mt-6 w-3 h-3 rounded-full"
                          >
                            &nbsp;
                          </div>
                          <div
                            v-if="item.activityLevel.value == 'INACTIVE'"
                            class="border border-white bg-black-200 -mt-6 w-3 h-3 rounded-full"
                          >
                            &nbsp;
                          </div>
                          <div
                            v-if="item.activityLevel.value == 'ERROR'"
                            class="border border-white bg-guardsman-red -mt-6 w-3 h-3 rounded-full"
                          >
                            &nbsp;
                          </div>
                        </context-popper>
                      </div>
                      <!-- eslint-disable-next-line -->
                      <context-popper :text="item.title">
                        <a :href="item.href" target="_blank">
                          <profile-image
                            v-if="item.src"
                            :size="40"
                            :img-url="item.src"
                            class="-mr-2"
                          />
                        </a>
                      </context-popper>
                    </template>
                  </div>
                </span>
                <span v-if="metadata.type == 'metadataList'">
                  <div
                    v-for="list in metadata.format(row, this)"
                    :key="list.label"
                    class="flex items-center"
                  >
                    <span class="text-xs uppercase mr-3 text-porcelain-300"
                      >{{ list.label }}:&nbsp;</span
                    >
                    <context-popper
                      v-for="item in list.items"
                      :key="item.icon"
                      :text="item.popover"
                    >
                      {{ item.icon }}
                    </context-popper>
                  </div>
                </span>
                <span v-if="metadata.type == 'createdByWithAvatar'">
                  <template v-if="row.createdBy?.hashedId">
                    <div class="flex items-center">
                      <div class="mr-4">
                        <profile-image
                          v-if="metadata.format(row.createdBy, this)"
                          :size="40"
                          :img-url="metadata.format(row.createdBy, this)"
                        />
                      </div>
                      <div>
                        <p class="leading-5">
                          {{ row.createdBy.firstName }}
                          {{ row.createdBy.lastName }}
                          <br />
                          <em class="text-xs text-blueGray-600">{{
                            row.createdBy.calc_EmailAddress
                          }}</em>
                        </p>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <em>System</em>
                  </template>
                </span>
                <span v-if="metadata.type == 'banner'">
                  <img
                    v-if="row.banner"
                    :src="row.banner.unsplashUrlRaw"
                    width="150"
                  />
                </span>
                <span v-if="metadata.type == 'formattedString'">
                  <div v-html="metadata.format(row, this)" />
                </span>
                <template v-if="metadata.type == 'decimalTimesOneHundred'">
                  <span v-if="name.split('.').length == 2">
                    {{ row[name.split(".")[0]][name.split(".")[1]] / 100 }}
                  </span>
                  <span v-else>
                    {{ row[name] / 100 }}
                  </span>
                </template>
                <template
                  v-if="metadata.type == 'nullableDecimalTimesOneHundred'"
                >
                  <span
                    v-if="
                      name.split('.').length == 2 &&
                      row[name.split('.')[0]][name.split('.')[1]] !== null
                    "
                  >
                    {{ row[name.split(".")[0]][name.split(".")[1]] / 100 }}
                  </span>
                  <span v-else-if="row[name] !== null">
                    {{ row[name] / 100 }}
                  </span>
                </template>
                <span v-if="metadata.type == 'markdown'">
                  <!-- <vue-markdown :source="row[name]" /> -->
                </span>
                <template v-if="metadata.type == 'relativeDate'">
                  <moment-from-now
                    v-if="row[name]"
                    class="text-sm"
                    :title="row[name]"
                    :value="row[name]"
                    :interval="5000"
                    :tag="'span'"
                  />
                </template>
                <template v-if="metadata.type == 'absoluteDate'">
                  <absolute-moment
                    v-if="row[name]"
                    class="text-sm"
                    style="white-space: pre"
                    :title="row[name]"
                    :value="row[name]"
                    :interval="5000"
                    :tag="'span'"
                  />
                </template>
              </td>
              <td
                v-if="
                  (actions.linkToRecord && actions.linkToRecord.allow) ||
                  (actions.viewDetail && actions.viewDetail.allow) ||
                  (actions.update && actions.update.allow) ||
                  (actions.delete && actions.delete.allow) ||
                  (actions.menuItems && actions.menuItems.length > 0)
                "
                class="px-3"
              >
                <div class="flex justify-end items-center">
                  <button
                    v-if="actions.linkToRecord && actions.linkToRecord.allow"
                    class="text-xs bg-porcelain-300 px-2 py-1 ml-2 text-white rounded"
                    @click.prevent.stop="
                      linkToRecord(actions.linkToRecord.formatUrl(row))
                    "
                  >
                    <i
                      aria-hidden="true"
                      class="fa-solid fa-arrow-up-right-from-square m-[2.5px]"
                    ></i>
                    <span class="sr-only">Link to Record</span>
                  </button>
                  <button
                    v-if="actions.viewDetail && actions.viewDetail.allow"
                    class="text-xs bg-porcelain-300 px-2 py-1 ml-2 text-white rounded"
                    @click.prevent.stop="openViewDetail(row, true)"
                  >
                    <i aria-hidden="true" class="far fa-file m-[2.5px]" />
                    <span class="sr-only">View Detail</span>
                  </button>
                  <button
                    v-if="actions.update && actions.update.allow"
                    class="text-xs bg-porcelain-300 px-2 py-1 ml-2 text-white rounded"
                    @click.prevent.stop="openUpdateForm(row, true)"
                  >
                    <i aria-hidden="true" class="far fa-edit m-[2.5px]" />
                    <span class="sr-only">Edit</span>
                  </button>
                  <button
                    v-if="actions.delete && actions.delete.allow"
                    class="text-xs bg-porcelain-300 px-2 py-1 ml-2 text-white rounded"
                    @click.prevent.stop="
                      deleteRow(row.hashedId, actions.delete.confirmFirst)
                    "
                  >
                    <i class="far fa-trash-alt m-[2.5px]" />
                    <span class="sr-only">Delete</span>
                  </button>
                  <regular-modal
                    :ref="`confirmModal${row.hashedId}`"
                    :showing="false"
                  >
                    <template #header>
                      <h4 class="text-xl font-light">Confirmation</h4>
                    </template>
                    <template #body>
                      <div
                        class="w-full max-h-[calc(100vh-200px)] overflow-scroll px-6 py-3"
                      >
                        <p>Are you sure you want to do this?</p>
                      </div>
                    </template>
                    <template #footer>
                      <button
                        class="modal-nonprimary-button"
                        type="button"
                        @click="toggleConfirmModal(row.hashedId)"
                      >
                        Cancel
                      </button>
                      <button
                        class="modal-primary-button"
                        type="button"
                        @click="
                          toggleConfirmModal(row.hashedId);
                          deleteRow(row.hashedId);
                        "
                      >
                        <span>Yes</span>
                      </button>
                    </template>
                  </regular-modal>
                  <table-dropdown
                    v-if="
                      (actions.menuItems && actions.menuItems.length > 0) ||
                      actions.menuItemFactory
                    "
                    :menu-items="
                      actions.menuItemFactory
                        ? actions.menuItemFactory(row)
                        : actions.menuItems
                    "
                    :row="row"
                    :vm="this"
                    :api-base-url="apiBaseUrl"
                    :api-prefix="apiPrefix"
                    @refresh="refresh"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="dataTable.loading"
        class="w-full min-h-[200px] flex items-center border border-solid border-porcelain-300 border-t-0"
      >
        <loader />
      </div>
    </div>
    <div class="flex pt-4 mt-3 pb-4 mb-3">
      <div class="w-full md:w-2/12">
        <span v-if="dataTable.offset > 0">
          <a
            href="#"
            @click.prevent="getData(dataTable.limit, tablePreviousOffset)"
          >
            Previous</a
          >
        </span>
      </div>
      <div class="w-full md:w-8/12 text-center">
        <p class="text-sm text-black/70" v-html="tableCursor"></p>
      </div>
      <div class="w-full md:w-2/12 text-right">
        <span v-if="dataTable.offset + dataTable.limit < dataTable.totalCount">
          <a
            href="#"
            @click.prevent="getData(dataTable.limit, tableNextOffset)"
          >
            Next</a
          >
        </span>
      </div>
    </div>

    <right-side-modal
      :id="'view-detail-' + apiPrefix + '-row-sidebar'"
      ref="viewDetailRowSidebar"
      data-model="showViewDetail"
      @hide="closeViewDetail()"
    >
      <template #header>
        <h3 class="text-xl block-title">View {{ singularLabel }}</h3>
      </template>
      <template #body>
        <div class="block block-themed block-transparent mb-0">
          <div class="block-content font-size-sm pb-5">
            <div
              v-for="(metadata, name) in viewDetail"
              :key="name"
              class="mx-5 my-3"
            >
              <template v-if="metadata.type != 'hidden' && metadata.qualifies">
                <div v-if="metadata.type == 'formattedString'" class="mb-2">
                  <div>
                    <strong>{{ metadata.label }}</strong>
                  </div>
                  <div v-html="metadata.value" />
                </div>
                <div v-if="metadata.type == 'markdown'" class="mb-2">
                  <div>
                    <strong>{{ metadata.label }}</strong>
                  </div>
                  <div>
                    <!-- <vue-markdown :source="metadata.value" /> -->
                  </div>
                </div>
                <div v-if="metadata.type == 'relativeDate'" class="mb-2">
                  <div>
                    <strong>{{ metadata.label }}</strong>
                  </div>
                  <div>
                    <moment-from-now
                      v-if="metadata.value"
                      :title="metadata.value"
                      :value="metadata.value"
                      :interval="5000"
                      :tag="'span'"
                    />
                  </div>
                </div>
                <div v-if="metadata.type == 'absoluteDate'" class="mb-2">
                  <div>
                    <strong>{{ metadata.label }}</strong>
                  </div>
                  <div>
                    <absolute-moment
                      v-if="metadata.value"
                      :title="metadata.value"
                      :value="metadata.value"
                      :interval="5000"
                      :tag="'span'"
                    />
                  </div>
                </div>
                <div v-if="metadata.type == 'dataTable'">
                  <div>
                    <span class="block text-blueGray-600 mb-1 text-2xl">{{
                      metadata.label
                    }}</span>
                  </div>
                  <div>
                    <div :id="'update-' + name + '-input'" class="-mx-5">
                      <DataTable
                        :admin-prefix="metadata.adminPrefix"
                        :api-prefix="metadata.apiPrefix"
                        :singular-label="metadata.singularLabel"
                        :plural-label="metadata.pluralLabel"
                        :create-form="metadata.createForm"
                        :update-form="metadata.updateForm"
                        :view-detail="metadata.viewDetail"
                        :columns="metadata.columns"
                        :actions="metadata.actions"
                        :parent-key="metadata.parentKey"
                        :parent-row="metadata.parentRow"
                        :nested-level="nestedLevel + 1"
                        @refresh="refresh"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="metadata.type == 'parentDataTable'">
                  <div>
                    <span class="block text-blueGray-600 mb-1 text-2xl">{{
                      metadata.label
                    }}</span>
                  </div>
                  <div>
                    <div :id="'update-' + name + '-input'" class="-mx-5">
                      <DataTable
                        :admin-prefix="metadata.adminPrefix"
                        :api-prefix="metadata.apiPrefix"
                        :singular-label="metadata.singularLabel"
                        :plural-label="metadata.pluralLabel"
                        :create-form="metadata.createForm"
                        :update-form="metadata.updateForm"
                        :view-detail="metadata.viewDetail"
                        :columns="metadata.columns"
                        :actions="metadata.actions"
                        :child-key="metadata.childKey"
                        :parent-row="metadata.parentRow"
                        :nested-level="nestedLevel + 1"
                        @refresh="refresh"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="metadata.type == 'revisionHistory'">
                  <div>
                    <span class="block text-blueGray-600 mb-1 text-2xl"
                      >Revision History</span
                    >
                  </div>
                  <div>
                    <div :id="'update-' + name + '-input'" class="">
                      <RevisionHistory
                        :revision-association-id="metadata"
                        :model-class-name="metadata.modelClassName"
                        :hashed-id="metadata.parentRow.hashedId"
                        :parent-row="metadata.parentRow"
                        :limit="metadata.limit"
                        :child-keys="metadata.childKeys"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </right-side-modal>

    <right-side-modal
      v-if="createForm"
      :id="'create-' + apiPrefix + '-row-sidebar'"
      ref="createRowSidebar"
      :prevent-close-on-click="true"
      @hide="closeCreateForm()"
    >
      <template #header>
        <h3 class="text-xl block-title">Add {{ singularLabel }}</h3>
      </template>
      <template #body>
        <div class="block block-themed block-transparent mb-0">
          <div class="block-content font-size-sm">
            <form class="w-100" @submit="onCreateSubmit" @reset="onCreateReset">
              <div
                v-for="(metadata, name) in createForm"
                :key="name"
                class="mx-5"
                :class="{
                  'my-5': metadata.type != 'hidden' && metadata.qualifies,
                }"
              >
                <template
                  v-if="metadata.type != 'hidden' && metadata.qualifies"
                >
                  <label
                    :label-for="'create-' + name + '-input'"
                    class="block uppercase text-blueGray-600 text-xs mb-1"
                    :class="{ 'text-red-500': metadata.errors?.length > 0 }"
                  >
                    {{ fieldLabel(metadata) }}
                  </label>
                  <p v-if="metadata.instruction" class="form-help">
                    {{ metadata.instruction }}
                  </p>
                  <p
                    v-if="metadata.type == 'instruction'"
                    :id="'create-' + name + '-instruction'"
                  >
                    {{ metadata.value }}
                  </p>
                  <input
                    v-if="metadata.type == 'string'"
                    :id="'create-' + name + '-input'"
                    v-model="metadata.value"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-chartreuseYellow w-full ease-linear transition-all duration-150"
                    type="text"
                    :placeholder="metadata.placeholder"
                    @input="validateInput(metadata, $event.target.value)"
                  />
                  <input
                    v-if="metadata.type == 'currency'"
                    :id="'create-' + name + '-input'"
                    v-model="metadata.value"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-chartreuseYellow w-full ease-linear transition-all duration-150"
                    type="text"
                    :placeholder="metadata.placeholder"
                    @input="validateInput(metadata, $event.target.value)"
                  />
                  <input
                    v-if="metadata.type == 'integer'"
                    :id="'create-' + name + '-input'"
                    v-model="metadata.value"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-chartreuseYellow w-full ease-linear transition-all duration-150"
                    type="number"
                    :placeholder="metadata.placeholder"
                    @input="validateInput(metadata, $event.target.value)"
                  />
                  <input
                    v-if="metadata.type == 'decimal'"
                    :id="'create-' + name + '-input'"
                    v-model="metadata.value"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-chartreuseYellow w-full ease-linear transition-all duration-150"
                    type="text"
                    :placeholder="metadata.placeholder"
                    @input="validateInput(metadata, $event.target.value)"
                  />
                  <div v-if="metadata.type == 'decimalTimesOneHundred'">
                    <input
                      :id="'create-' + name + '-input'"
                      v-model="metadata.value"
                      type="hidden"
                      @input="validateInput(metadata, $event.target.value)"
                    />
                    <input
                      :id="'create-' + name + '-input-mask'"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-chartreuseYellow w-full ease-linear transition-all duration-150"
                      type="text"
                      :placeholder="metadata.placeholder"
                      @input="
                        adjustValue(
                          metadata,
                          function (v) {
                            return v * 100;
                          },
                          $event,
                        )
                      "
                    />
                  </div>
                  <div v-if="metadata.type == 'nullableDecimalTimesOneHundred'">
                    <input
                      :id="'create-' + name + '-input'"
                      v-model="metadata.value"
                      type="hidden"
                      @input="validateInput(metadata, $event.target.value)"
                    />
                    <input
                      :id="'create-' + name + '-input-mask'"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-chartreuseYellow w-full ease-linear transition-all duration-150"
                      type="text"
                      :placeholder="metadata.placeholder"
                      @input="
                        adjustValue(
                          metadata,
                          function (v) {
                            return v * 100;
                          },
                          $event,
                        )
                      "
                    />
                  </div>
                  <markdown-editor
                    v-if="metadata.type == 'text'"
                    :id="'create-' + name + '-input'"
                    v-model="metadata.value"
                    height="200px"
                    class="placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-chartreuseYellow w-full ease-linear transition-all duration-150"
                  />
                  <!-- <textarea
                    v-if="metadata.type == 'text'"
                    :id="'create-' + name + '-input'"
                    v-model="metadata.value"
                    :placeholder="metadata.placeholder"
                    rows="3"
                    @input="validateInput(metadata, $event.target.value)"
                  /> -->
                  <textarea
                    v-if="metadata.type == 'textlines'"
                    :id="'create-' + name + '-input'"
                    v-model="metadata.value"
                    :placeholder="metadata.placeholder"
                    rows="3"
                    @input="validateInput(metadata, $event.target.value)"
                  />
                  <div
                    v-if="metadata.type == 'dataTable'"
                    :id="'create-' + name + '-input'"
                    class="py-2 px-3 bg-white border border-gray"
                  >
                    <em
                      >Please create this {{ singularLabel }} before attempting
                      to add {{ fieldLabel(metadata) }}.</em
                    >
                  </div>
                  <!-- <vue-simplemde-patched
                    v-if="metadata.type == 'markdown'"
                    :ref="'create' + name + 'markdownEditor'"
                    v-model="metadata.value"
                  /> -->
                  <div v-if="metadata.type == 'readOnly'">
                    {{ metadata.value }}
                  </div>
                  <input
                    v-if="metadata.type == 'hidden'"
                    :id="'create-' + name + '-input'"
                    v-model="metadata.value"
                    type="hidden"
                    required
                    :placeholder="metadata.placeholder"
                  />
                  <datepicker
                    v-if="metadata.type == 'date'"
                    :id="'create-' + name + '-input'"
                    v-model="metadata.value"
                    placeholder="Select date"
                    input-class="py-3"
                    @update="metadata.value = $event"
                  ></datepicker>
                  <!-- <flat-pickr
                    v-if="metadata.type == 'datetime'"
                    :id="'create-' + name + '-input'"
                    v-model="metadata.value"
                    class="form-control bg-white"
                    data-utc="true"
                    :config="{
                      dateFormat: 'D, d M Y H:i:S',
                      parseDate: parseDate,
                      enableTime: true
                    }"
                  /> -->
                  <autocomplete-select
                    v-if="metadata.type == 'autocomplete-multiselect'"
                    :id="'create-' + name + '-input'"
                    v-model="metadata.value"
                    :placeholder="metadata.placeholder"
                    :id-key="metadata.idKey"
                    :label-key="metadata.labelKey"
                    :label-function="metadata.labelFunction"
                    :autocomplete-endpoint="metadata.autocompleteEndpoint"
                    :initial-options="
                      metadata.value.map((i) => {
                        return {
                          id: i[metadata.idKey],
                          label: i[metadata.labelKey],
                        };
                      })
                    "
                    :multiple="true"
                    :search-on-focus="metadata.searchOnFocus"
                  />
                  <autocomplete-select
                    v-if="metadata.type == 'autocomplete-select'"
                    :id="'create-' + name + '-input'"
                    v-model="metadata.value"
                    :placeholder="metadata.placeholder"
                    :id-key="metadata.idKey"
                    :label-key="metadata.labelKey"
                    :label-function="metadata.labelFunction"
                    :autocomplete-endpoint="metadata.autocompleteEndpoint"
                    :filters="metadata.filters"
                    :initial-options="
                      metadata.value
                        ? [
                            {
                              id: metadata.value[metadata.idKey],
                              label: metadata.value[metadata.labelKey],
                            },
                          ]
                        : []
                    "
                    :multiple="false"
                    :search-on-focus="metadata.searchOnFocus"
                    @input="metadata.value = $event"
                  />
                  <v-select
                    v-if="metadata.type == 'select'"
                    :id="'create-' + name + '-input'"
                    v-model="metadata.value"
                    :placeholder="metadata.placeholder"
                    :clearable="metadata.clearable"
                    :options="metadata.options"
                    :search-on-focus="metadata.searchOnFocus"
                    :multiple="false"
                  />
                  <dropzone
                    v-if="metadata.type == 'file'"
                    :id="'create-' + name + '-input'"
                    :max-files="1"
                    :files="metadata.value"
                    :options="metadata.dropzoneOptions"
                    @uploaded="metadata.value = $event"
                  ></dropzone>
                  <label>
                    <input
                      v-if="metadata.type == 'boolean'"
                      :id="'create-' + name + '-input'"
                      v-model="metadata.value"
                      type="checkbox"
                      class="mb-1"
                      switch
                    />
                    <span class="ml-2">{{ metadata.checkboxLabel }}</span>
                  </label>
                  <template v-if="metadata.errors?.length">
                    <p
                      v-for="(error, index) in metadata.errors"
                      :key="index"
                      class="text-red-500 mt-2"
                      v-html="error"
                    ></p>
                  </template>
                </template>
              </div>
            </form>
          </div>
        </div>
      </template>
      <template #footer>
        <div v-if="!hideCreateFormButtons" class="text-right">
          <button
            type="button"
            class="text-black bg-transparent hover:bg-porcelain-200 active:bg-porcelain-300 uppercase text-sm px-6 py-2 rounded outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 mr-2"
            @click.prevent="onCreateReset"
          >
            Cancel
          </button>
          <button
            type="button"
            class="text-black bg-transparent border border-solid border-black-500 hover:shadow-md hover:bg-chartreuseYellow active:bg-chartreuseYellow-600 font-bold uppercase text-sm px-6 py-2 rounded outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            @click.prevent="onCreateSubmit"
          >
            Submit
          </button>
        </div>
      </template>
    </right-side-modal>

    <right-side-modal
      :id="'update-' + apiPrefix + '-row-sidebar'"
      ref="updateRowSidebar"
      :prevent-close-on-click="true"
      @hide="closeUpdateForm()"
    >
      <template #header>
        <h3 class="text-xl block-title">Update {{ singularLabel }}</h3>
      </template>
      <template #body>
        <div class="block block-themed block-transparent mb-0">
          <div class="block-content font-size-sm">
            <form class="w-100" @submit="onUpdateSubmit" @reset="onUpdateReset">
              <div
                v-for="(metadata, name) in updateForm"
                :key="name"
                class="px-5"
                :class="{
                  'mt-5': metadata.type != 'hidden' && metadata.qualifies,
                  'border-t pt-5 border-porcelain-300':
                    metadata.type != 'hidden' &&
                    metadata.qualifies &&
                    ['dataTable', 'parentDataTable', 'revisionHistory'].indexOf(
                      metadata.type,
                    ) > -1,
                }"
              >
                <template
                  v-if="metadata.type != 'hidden' && metadata.qualifies"
                >
                  <label
                    :label-for="'update-' + name + '-input'"
                    class="block text-blueGray-600 mb-1"
                    :class="{
                      'uppercase text-xs':
                        [
                          'dataTable',
                          'parentDataTable',
                          'revisionHistory',
                        ].indexOf(metadata.type) == -1,
                      'text-2xl':
                        [
                          'dataTable',
                          'parentDataTable',
                          'revisionHistory',
                        ].indexOf(metadata.type) > -1,
                      'text-red-500': metadata.errors?.length > 0,
                    }"
                  >
                    {{ fieldLabel(metadata) }}
                  </label>
                  <p v-if="metadata.instruction" class="form-help">
                    {{ metadata.instruction }}
                  </p>
                  <p
                    v-if="metadata.type == 'instruction'"
                    :id="'update-' + name + '-instruction'"
                  >
                    {{ metadata.default }}
                  </p>
                  <input
                    v-if="metadata.type == 'string'"
                    :id="'update-' + name + '-input'"
                    v-model="metadata.value"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-chartreuseYellow w-full ease-linear transition-all duration-150"
                    type="text"
                    :placeholder="metadata.placeholder"
                    @input="validateInput(metadata, $event.target.value)"
                  />
                  <input
                    v-if="metadata.type == 'currency'"
                    :id="'update-' + name + '-input'"
                    v-model="metadata.value"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-chartreuseYellow w-full ease-linear transition-all duration-150"
                    type="text"
                    :placeholder="metadata.placeholder"
                    @input="validateInput(metadata, $event.target.value)"
                  />
                  <input
                    v-if="metadata.type == 'integer'"
                    :id="'update-' + name + '-input'"
                    v-model="metadata.value"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-chartreuseYellow w-full ease-linear transition-all duration-150"
                    type="number"
                    :placeholder="metadata.placeholder"
                    @input="validateInput(metadata, $event.target.value)"
                  />
                  <input
                    v-if="metadata.type == 'decimal'"
                    :id="'update-' + name + '-input'"
                    v-model="metadata.value"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-chartreuseYellow w-full ease-linear transition-all duration-150"
                    type="text"
                    :placeholder="metadata.placeholder"
                    @input="validateInput(metadata, $event.target.value)"
                  />
                  <div v-if="metadata.type == 'decimalTimesOneHundred'">
                    <input
                      :id="'update-' + name + '-input'"
                      v-model="metadata.value"
                      type="hidden"
                      @input="validateInput(metadata, $event.target.value)"
                    />
                    <input
                      :id="'update-' + name + '-input-mask'"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-chartreuseYellow w-full ease-linear transition-all duration-150"
                      type="text"
                      :value="metadata.value / 100"
                      :placeholder="metadata.placeholder"
                      @input="
                        adjustValue(
                          metadata,
                          function (v) {
                            return v * 100;
                          },
                          $event,
                        )
                      "
                    />
                  </div>
                  <div v-if="metadata.type == 'nullableDecimalTimesOneHundred'">
                    <input
                      :id="'update-' + name + '-input'"
                      v-model="metadata.value"
                      type="hidden"
                      @input="validateInput(metadata, $event.target.value)"
                    />
                    <input
                      :id="'update-' + name + '-input-mask'"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-chartreuseYellow w-full ease-linear transition-all duration-150"
                      type="text"
                      :value="
                        metadata.value !== null ? metadata.value / 100 : null
                      "
                      :placeholder="metadata.placeholder"
                      @input="
                        adjustValue(
                          metadata,
                          function (v) {
                            return v * 100;
                          },
                          $event,
                        )
                      "
                    />
                  </div>
                  <markdown-editor
                    v-if="metadata.type == 'text'"
                    :id="'update-' + name + '-input'"
                    v-model="metadata.value"
                    height="200px"
                    class="placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-chartreuseYellow w-full ease-linear transition-all duration-150"
                  />
                  <!-- <textarea
                    v-if="metadata.type == 'text'"
                    :id="'update-' + name + '-input'"
                    v-model="metadata.value"
                    :placeholder="metadata.placeholder"
                    rows="3"
                    @input="validateInput(metadata, $event.target.value)"
                  /> -->
                  <textarea
                    v-if="metadata.type == 'textlines'"
                    :id="'update-' + name + '-input'"
                    v-model="metadata.value"
                    :placeholder="metadata.placeholder"
                    rows="3"
                    @input="validateInput(metadata, $event.target.value)"
                  />
                  <div
                    v-if="metadata.type == 'dataTable'"
                    :id="'update-' + name + '-input'"
                    class="-mx-5"
                  >
                    <DataTable
                      :admin-prefix="metadata.adminPrefix"
                      :api-prefix="metadata.apiPrefix"
                      :singular-label="metadata.singularLabel"
                      :plural-label="metadata.pluralLabel"
                      :hide-create-form-buttons="metadata.hideCreateFormButtons"
                      :create-form="metadata.createForm"
                      :update-form="metadata.updateForm"
                      :view-detail="metadata.viewDetail"
                      :columns="metadata.columns"
                      :actions="metadata.actions"
                      :parent-key="metadata.parentKey"
                      :parent-row="metadata.parentRow"
                      :nested-level="nestedLevel + 1"
                      @refresh="refresh"
                    />
                  </div>
                  <div
                    v-if="metadata.type == 'parentDataTable'"
                    :id="'update-' + name + '-input'"
                    class="-mx-5"
                  >
                    <DataTable
                      :admin-prefix="metadata.adminPrefix"
                      :api-prefix="metadata.apiPrefix"
                      :singular-label="metadata.singularLabel"
                      :plural-label="metadata.pluralLabel"
                      :create-form="metadata.createForm"
                      :update-form="metadata.updateForm"
                      :view-detail="metadata.viewDetail"
                      :columns="metadata.columns"
                      :actions="metadata.actions"
                      :child-key="metadata.childKey"
                      :parent-row="metadata.parentRow"
                      :nested-level="nestedLevel + 1"
                      @refresh="refresh"
                    />
                  </div>
                  <div
                    v-if="metadata.type == 'revisionHistory'"
                    :id="'update-' + name + '-input'"
                    class=""
                  >
                    <RevisionHistory
                      :revision-association-id="metadata"
                      :model-class-name="metadata.modelClassName"
                      :hashed-id="metadata.parentRow.hashedId"
                      :parent-row="metadata.parentRow"
                      :limit="metadata.limit"
                      :child-keys="metadata.childKeys"
                    />
                  </div>
                  <!-- <vue-simplemde-patched
                    v-if="metadata.type == 'markdown'"
                    :ref="'update' + name + 'markdownEditor'"
                    v-model="metadata.value"
                  /> -->
                  <div v-if="metadata.type == 'readOnly'">
                    {{ metadata.value }}
                  </div>
                  <input
                    v-if="metadata.type == 'hidden'"
                    :id="'update-' + name + '-input'"
                    v-model="metadata.value"
                    type="hidden"
                    :placeholder="metadata.placeholder"
                  />
                  <datepicker
                    v-if="metadata.type == 'date'"
                    :id="'update-' + name + '-input'"
                    v-model="metadata.value"
                    placeholder="Select date"
                    input-class="py-3"
                    @update="metadata.value = $event"
                  ></datepicker>
                  <!-- <flat-pickr
                    v-if="metadata.type == 'datetime'"
                    :id="'update-' + name + '-input'"
                    v-model="metadata.value"
                    class="form-control bg-white"
                    data-utc="true"
                    :config="{
                      dateFormat: 'D, d M Y H:i:S',
                      parseDate: parseDate,
                      enableTime: true
                    }"
                  /> -->
                  <autocomplete-select
                    v-if="metadata.type == 'autocomplete-multiselect'"
                    :id="'update-' + name + '-input'"
                    v-model="metadata.value"
                    :placeholder="metadata.placeholder"
                    :id-key="metadata.idKey"
                    :label-key="metadata.labelKey"
                    :label-function="metadata.labelFunction"
                    :autocomplete-endpoint="metadata.autocompleteEndpoint"
                    :initial-options="
                      metadata.value.map((i) => {
                        return {
                          id: i[metadata.idKey],
                          label: i[metadata.labelKey],
                        };
                      })
                    "
                    :multiple="true"
                    :search-on-focus="metadata.searchOnFocus"
                  />
                  <autocomplete-select
                    v-if="metadata.type == 'autocomplete-select'"
                    :id="'update-' + name + '-input'"
                    v-model="metadata.value"
                    :placeholder="metadata.placeholder"
                    :id-key="metadata.idKey"
                    :label-key="metadata.labelKey"
                    :autocomplete-endpoint="metadata.autocompleteEndpoint"
                    :filters="metadata.filters"
                    :label-function="metadata.labelFunction"
                    :initial-options="
                      metadata.value
                        ? [
                            {
                              id: metadata.value[metadata.idKey],
                              label: metadata.value[metadata.labelKey],
                            },
                          ]
                        : []
                    "
                    :multiple="false"
                    :search-on-focus="metadata.searchOnFocus"
                    @input="metadata.value = $event"
                  />
                  <v-select
                    v-if="metadata.type == 'select'"
                    :id="'update-' + name + '-input'"
                    v-model="metadata.value"
                    :placeholder="metadata.placeholder"
                    :clearable="metadata.clearable"
                    :options="metadata.options"
                    :multiple="false"
                    :search-on-focus="metadata.searchOnFocus"
                  />
                  <dropzone
                    v-if="metadata.type == 'file'"
                    :id="'update-' + name + '-input'"
                    :max-files="1"
                    :files="metadata.value"
                    :options="metadata.dropzoneOptions"
                    @uploaded="metadata.value = $event.files"
                  ></dropzone>
                  <!-- <b-form-checkbox
                    v-if="metadata.type == 'boolean'"
                    :id="'update-' + name + '-input'"
                    v-model="metadata.value"
                    class="mb-1"
                    switch
                  /> -->
                  <label>
                    <input
                      v-if="metadata.type == 'boolean'"
                      :id="'update-' + name + '-input'"
                      v-model="metadata.value"
                      type="checkbox"
                      class="mb-1"
                      switch
                    />
                    <span class="ml-2">{{ metadata.checkboxLabel }}</span>
                  </label>
                  <template v-if="metadata.errors?.length">
                    <p
                      v-for="(error, index) in metadata.errors"
                      :key="index"
                      class="text-red-500 mt-2"
                      v-html="error"
                    ></p>
                  </template>
                </template>
              </div>
            </form>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="text-right">
          <button
            type="button"
            class="text-black bg-transparent hover:bg-porcelain-200 active:bg-porcelain-300 uppercase text-sm px-6 py-2 rounded outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 mr-2"
            @click.prevent="onUpdateReset"
          >
            Cancel
          </button>
          <button
            type="button"
            class="text-black bg-transparent border border-solid border-black-500 hover:shadow-md hover:bg-chartreuseYellow active:bg-chartreuseYellow-600 font-bold uppercase text-sm px-6 py-2 rounded outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            @click.prevent="onUpdateSubmit"
          >
            Update
          </button>
        </div>
      </template>
    </right-side-modal>
  </div>
</template>

<script>
import { compile } from "vue/dist/vue.esm-bundler.js"; // eslint-disable-line
import vClickOutside from "click-outside-vue3";
import { utils } from "common-frontend";
import TableDropdown from "../Dropdowns/TableDropdown.vue";
import Datepicker from "../Datepicker.vue";
import Dropzone from "../Dropzone.vue";
import MomentFromNow from "../MomentFromNow.vue";
import AbsoluteMoment from "../AbsoluteMoment.vue";
import RightSideModal from "../Modals/RightSide.vue";
import RegularModal from "../Modals/Regular.vue";
import AutocompleteSelect from "../AutocompleteSelect.vue";
import ProfileImage from "../ProfileImage.vue";
import ContextPopper from "../ContextPopper.vue";
import Loader from "../Loader.vue";
import MarkdownEditor from "../MarkdownEditor.vue";
import RevisionHistory from "../RevisionHistory.vue";

const { snakeToCamel, keysToCamel, keysToSnake } = utils;

const slugValidator = (string) => {
  const regex = "^[a-z](-?[a-z])*$";
  return string.match(regex);
};

const emailValidator = (string) => {
  const regex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return string.match(regex);
};

export default {
  name: "DataTable",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    TableDropdown,
    Datepicker,
    MomentFromNow,
    AbsoluteMoment,
    RegularModal,
    RightSideModal,
    AutocompleteSelect,
    ProfileImage,
    ContextPopper,
    Loader,
    MarkdownEditor,
    Dropzone,
    RevisionHistory,
  },
  props: {
    nestedLevel: Number,
    columns: Object,
    createForm: Object,
    hideCreateFormButtons: Boolean,
    updateForm: Object,
    viewDetail: Object,
    actions: Object,
    apiPrefix: String,
    adminPrefix: String,
    testBooleanKey: String,
    singularLabel: String,
    pluralLabel: String,
    immutableFilters: Object,
    parentRow: Object,
    parentKey: String,
    childKey: Function,
    updateMethodOverride: String,
  },
  data() {
    return {
      apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
      getPath: `/${this.apiPrefix}/`,
      createPath(hashedId) {
        if (this.actions?.create?.endpoint) {
          return this.actions?.create?.endpoint(hashedId);
        }
        return `/${this.apiPrefix}/`;
      },
      showViewDetail: false,
      updatePath(hashedId) {
        return `/${this.apiPrefix}/${hashedId}/`;
      },
      updateMethod: this.updateMethodOverride
        ? this.updateMethodOverride
        : "put",
      deletePath(hashedId) {
        return `/${this.apiPrefix}/${hashedId}/`;
      },
      shownColumns: [],
      showColumnSelector: false,
      dataTable: {
        loading: false,
        results: [],
        sort: [],
        filters: {},
        offset: 0,
        limit: 0,
        totalCount: 0,
      },
      filterTimeout: null,
    };
  },
  computed: {
    tableCursor() {
      if (this.dataTable.results.length === 0) {
        if (
          this.dataTable.filters &&
          Object.keys(this.dataTable.filters).length > 0
        ) {
          return `No matching ${this.pluralLabel} found.`;
        }
        if (this.dataTable.loading) {
          return "Loading...";
        }
        return "No results.";
      }
      let matchClause = ` total ${this.pluralLabel}`;
      if (Object.keys(this.dataTable.filters).length) {
        matchClause = ` matching ${this.pluralLabel}`;
      }
      return `Showing ${this.dataTable.offset / 1 + 1} -
        ${Math.min(
          this.dataTable.offset / 1 + this.dataTable.limit / 1,
          this.dataTable.totalCount / 1,
        )}
        of ${this.dataTable.totalCount}${matchClause}.`;
    },
    tablePreviousOffset() {
      return Math.max(
        this.dataTable.offset / 1 - this.dataTable.limit / 1 - 1,
        0,
      );
    },
    tableNextOffset() {
      return this.dataTable.offset / 1 + this.dataTable.limit / 1;
    },
    tableSortKeys() {
      return Object.assign({}, ...this.dataTable.sort);
    },
  },
  created() {
    // figure out default visibility, sorts, and filters
    const defaultSort = [];
    const defaultFilters = {};
    Object.keys(this.columns).forEach((key) => {
      if (this.columns[key].sort.default) {
        defaultSort.push({ [key]: this.columns[key].sort.default });
      }
      if (this.$route.query[key]) {
        defaultFilters[key] = JSON.parse(this.$route.query[key]);
      } else if (this.columns[key].filter.default) {
        defaultFilters[key] = this.columns[key].filter.default;
      }
      if (
        (this.nestedLevel === 0 &&
          key === "hashedId" &&
          this.$route.params.hashedId0) ||
        (this.nestedLevel === 1 &&
          key === "hashedId" &&
          this.$route.params.hashedId1) ||
        !this.columns[key].hidden ||
        this.columns[key].hidden === "never"
      ) {
        this.shownColumns.push(key);
      }
    });
    if (this.parentKey && this.parentRow && this.parentRow.hashedId) {
      defaultFilters[this.parentKey] = this.parentRow.hashedId;
    }
    if (this.nestedLevel === 0 && this.$route.params.hashedId0) {
      this.dataTable.limit = 20;
      this.dataTable.filters.hashedId = this.$route.params.hashedId0;
      this.invokeFilter("hashedId");
    } else if (
      this.nestedLevel === 1 &&
      this.$route.params.itemType === this.apiPrefix &&
      this.$route.params.hashedId1
    ) {
      this.dataTable.limit = 20;
      this.dataTable.filters.hashedId = this.$route.params.hashedId1;
      this.invokeFilter("hashedId");
    } else if (this.childKey && this.parentRow) {
      this.dataTable.limit = 1;
      this.dataTable.filters.hashedId = this.childKey(this.parentRow);
      this.invokeFilter("hashedId");
    } else {
      this.getData(20, 0, defaultSort, defaultFilters);
    }
    this.initCreateForm(this.parentRow);
  },
  methods: {
    consoleLog(item) {
      console.log(item); // eslint-disable-line
    },
    getData(
      limit = this.dataTable.limit,
      offset = this.dataTable.offset,
      sort = this.dataTable.sort,
      filters = this.dataTable.filters,
    ) {
      this.dataTable.loading = true;
      this.$api.axios
        .get(this.getPath, {
          params: keysToSnake({
            limit,
            offset,
            sort: JSON.stringify(keysToSnake(sort)),
            filters: JSON.stringify(keysToSnake(filters)),
          }),
        })
        .then((res) => {
          const data = keysToCamel(res.data);
          this.dataTable = data;
          this.dataTable.loading = false;
          if (
            this.nestedLevel === 0 &&
            this.$route.params.hashedId0 &&
            this.$route.params.action0
          ) {
            const selected = data.results.find(
              (row) =>
                row.hashedId && row.hashedId === this.$route.params.hashedId0,
            );
            if (selected && this.$route.params.action0 === "edit") {
              this.openUpdateForm(selected);
            } else if (selected && this.$route.params.action0 === "view") {
              this.openViewDetail(selected);
            }
          }
          if (this.nestedLevel === 0 && this.$route.params.action0) {
            if (this.$route.params.action0 === "create") {
              this.openCreateForm();
            }
          }
          if (
            this.nestedLevel === 1 &&
            this.$route.params.hashedId1 &&
            this.$route.params.action1
          ) {
            const selected = data.results.find(
              (row) =>
                row.hashedId && row.hashedId === this.$route.params.hashedId1,
            );
            if (selected && this.$route.params.action1 === "edit") {
              this.openUpdateForm(selected);
            } else if (selected && this.$route.params.action1 === "view") {
              this.openViewDetail(selected);
            }
          }
          if (this.nestedLevel === 1 && this.$route.params.action1) {
            if (this.$route.params.action1 === "create") {
              this.openCreateForm();
            }
          }
        })
        .catch(() => {
          this.dataTable.loading = false;
        });
    },
    toggleModal(modalName) {
      let ref = this.$refs[modalName];
      if (Array.isArray(ref)) {
        ref = ref[0]; // eslint-disable-line
      }
      ref.toggleModal();
    },
    openModal(modalName) {
      let ref = this.$refs[modalName];
      if (Array.isArray(ref)) {
        ref = ref[0]; // eslint-disable-line
      }
      if (!ref.showModal) {
        ref.toggleModal();
      }
    },
    parseDate(dateString) {
      // eslint-disable-next-line
      const tzDate = new this.moment(dateString);

      return new Date(
        tzDate.year(),
        tzDate.month(),
        tzDate.date(),
        tzDate.hour(),
        tzDate.minute(),
        tzDate.second(),
      );
    },
    refresh() {
      this.getData();
      this.$emit("refresh");
    },
    createRow(hashedId, payload) {
      this.$api.axios
        .post(this.createPath(hashedId), payload)
        .then((res) => {
          this.toggleModal(`createRowSidebar`);
          this.initCreateForm(this.parentRow);
          this.getData();
          this.$emit("refresh");
          const data = keysToCamel(res.data);
          if (data.copyLink) {
            const dataItem = [
              new ClipboardItem({
                "text/plain": new Blob([data.copyLink], { type: "text/plain" }),
              }),
            ];
            const self = this;
            navigator.clipboard.write(dataItem).then(function _() {
              self.$toast.success("Copied to clipboard.");
            });
          } else {
            this.$toast.success(
              `A new ${this.singularLabel} has been created.`,
            );
          }
        })
        .catch((error) => {
          if (error.response.status === 409) {
            this.$toast.error("This appears to be a duplicate.");
          } else {
            this.$toast.error(error);
          }
        });
    },
    updateRow(hashedId, payload) {
      this.$api.axios[this.updateMethod](this.updatePath(hashedId), payload)
        .then(() => {
          this.$emit("row-updated", { hashedId, payload });
          this.toggleModal(`updateRowSidebar`);
          this.initUpdateForm(null);
          this.getData();
          this.$emit("refresh");
          this.$toast.success(`This ${this.singularLabel} has been updated.`);
        })
        .catch((error) => {
          this.$toast.error(error);
        });
    },
    deleteRow(hashedId, confirmFirst) {
      if (confirmFirst) {
        this.toggleConfirmModal(hashedId);
      } else {
        this.$api.axios
          .delete(this.deletePath(hashedId))
          .then(() => {
            this.$emit("row-deleted", { hashedId });
            this.getData();
            this.$emit("refresh");
            this.$toast.success(`This ${this.singularLabel} has been deleted.`);
          })
          .catch((error) => {
            this.$toast.error(error);
          });
      }
    },
    linkToRecord(url) {
      window.open(
        `${import.meta.env.VITE_ADMIN_BASE_URL}/admin/${url}`,
        "_blank",
      );
    },
    openViewDetail(row, changeRoute) {
      if (changeRoute && this.nestedLevel === 0) {
        this.$router.push({
          name: "AdminBaseDataTableItem",
          params: {
            itemType0: this.$route.params.itemType0,
            hashedId0: row.hashedId,
            action0: "view",
          },
        });
      } else if (changeRoute && this.nestedLevel === 1) {
        this.$router.push({
          name: "AdminBaseDataTableNestedItem",
          params: {
            itemType0: this.$route.params.itemType0,
            hashedId0: this.$route.params.hashedId0,
            action0: this.$route.params.action0,
            itemType1: this.adminPrefix,
            hashedId1: row.hashedId,
            action1: "view",
          },
        });
      }
      this.openModal(`viewDetailRowSidebar`);
      this.initViewDetail(row);
    },
    closeViewDetail() {
      if (this.nestedLevel === 0) {
        this.$router.push({
          name: "AdminBaseDataTable",
          params: { itemType0: this.$route.params.itemType0 },
        });
      } else if (this.nestedLevel === 1) {
        this.$router.push({
          name: "AdminBaseDataTableItem",
          params: {
            itemType0: this.$route.params.itemType0,
            hashedId0: this.$route.params.hashedId0,
            action0: this.$route.params.action0,
          },
        });
      }
      this.toggleModal(`viewDetailRowSidebar`);
    },
    openUpdateForm(row, changeRoute) {
      if (changeRoute && this.nestedLevel === 0) {
        this.$router.push({
          name: "AdminBaseDataTableItem",
          params: {
            itemType0: this.$route.params.itemType0,
            hashedId0: row.hashedId,
            action0: "edit",
          },
        });
      } else if (changeRoute && this.nestedLevel === 1) {
        this.$router.push({
          name: "AdminBaseDataTableNestedItem",
          params: {
            itemType0: this.$route.params.itemType0,
            hashedId0: this.$route.params.hashedId0,
            action0: this.$route.params.action0,
            itemType1: this.adminPrefix,
            hashedId1: row.hashedId,
            action1: "edit",
          },
        });
      }
      this.openModal(`updateRowSidebar`);
      this.initUpdateForm(row);
    },
    closeUpdateForm() {
      if (this.nestedLevel === 0) {
        this.$router.push({
          name: "AdminBaseDataTable",
          params: { itemType0: this.$route.params.itemType0 },
        });
      } else if (this.nestedLevel === 1) {
        this.$router.push({
          name: "AdminBaseDataTableItem",
          params: {
            itemType0: this.$route.params.itemType0,
            hashedId0: this.$route.params.hashedId0,
            action0: this.$route.params.action0,
          },
        });
      }
    },
    openCreateForm(changeRoute) {
      if (changeRoute && this.nestedLevel === 0) {
        this.$router.push({
          name: "AdminBaseDataTableCreateItem",
          params: {
            itemType0: this.$route.params.itemType0,
            action0: "create",
          },
        });
      }
      if (changeRoute && this.nestedLevel === 1) {
        this.$router.push({
          name: "AdminBaseDataTableCreateNestedItem",
          params: {
            itemType0: this.$route.params.itemType0,
            hashedId0: this.$route.params.hashedId0,
            action0: this.$route.params.action0,
            itemType1: this.adminPrefix,
            action1: "create",
          },
        });
      }
      this.openModal(`createRowSidebar`);
    },
    closeCreateForm() {
      if (this.nestedLevel === 0) {
        this.$router.push({
          name: "AdminBaseDataTable",
          params: { itemType0: this.$route.params.itemType0 },
        });
      }
      if (this.nestedLevel === 1) {
        this.$router.push({
          name: "AdminBaseDataTableItem",
          params: {
            itemType0: this.$route.params.itemType0,
            hashedId0: this.$route.params.hashedId0,
            action0: this.$route.params.action0,
          },
        });
      }
    },
    toggleConfirmModal(modalName) {
      let ref = this.$refs[`confirmModal${modalName}`];
      if (Array.isArray(ref)) {
        ref = ref[0]; // eslint-disable-line
      }
      ref.toggleModal();
    },
    initCreateForm(parentRow) {
      if (this.createForm) {
        Object.keys(this.createForm).forEach((key) => {
          this.createForm[key].qualifies = true;
          if (
            this.createForm[key].qualifier &&
            !this.createForm[key].qualifier(parentRow, this)
          ) {
            this.createForm[key].qualifies = false;
          }
          if (typeof this.createForm[key].default === "function") {
            this.createForm[key].value = this.createForm[key].default(
              parentRow,
              this,
            );
          } else {
            this.createForm[key].value = this.createForm[key].default;
          }
          if (this.createForm[key].autocompleteEndpointFunction) {
            this.createForm[key].autocompleteEndpoint =
              this.createForm[key].autocompleteEndpointFunction(parentRow);
          }
        });
      }
    },
    onCreateSubmit(evt) {
      evt.preventDefault();
      const submission = {};
      let hasErrors = false;
      Object.keys(this.createForm).forEach((key) => {
        if (
          !this.validateInput(this.createForm[key], this.createForm[key].value)
        ) {
          hasErrors = true;
        }
        submission[key] = this.createForm[key].value;
      });
      if (!hasErrors) {
        const payload = keysToSnake(submission, true);
        this.createRow(this.createForm.hashedId?.value, payload);
      } else {
        this.$toast.warning(
          "The form has one or more errors. Please see the validation messages under each field for more information.",
        );
      }
    },
    onCreateReset(evt) {
      evt.preventDefault();
      this.toggleModal(`createRowSidebar`);
      this.initCreateForm(this.parentRow);
    },
    initViewDetail(row) {
      if (row) {
        Object.keys(this.viewDetail).forEach((key) => {
          this.viewDetail[key].qualifies = true;
          if (
            this.viewDetail[key].qualifier &&
            !this.viewDetail[key].qualifier(row, this)
          ) {
            this.viewDetail[key].qualifies = false;
          }
          if (this.viewDetail[key].type === "formattedString") {
            this.viewDetail[key].value = this.viewDetail[key].format(row, this);
          } else if (this.viewDetail[key].type === "markdown") {
            this.viewDetail[key].value =
              this.viewDetail[key].format(row, this) || "";
          } else if (this.viewDetail[key].type === "dataTable") {
            this.viewDetail[key].parentRow = row;
          } else if (this.viewDetail[key].type === "revisionHistory") {
            this.viewDetail[key].parentRow = row;
          } else if (this.viewDetail[key].type === "parentDataTable") {
            this.viewDetail[key].parentRow = row;
          } else if (this.viewDetail[key].type === "relativeDate") {
            this.viewDetail[key].value = row[key];
          } else if (this.viewDetail[key].type === "absoluteDate") {
            this.viewDetail[key].value = row[key];
          }
        });
      } else {
        Object.keys(this.viewDetail).forEach((key) => {
          this.viewDetail[key].value = undefined;
        });
      }
    },
    adjustValue(metadata, func, event) {
      metadata.value = event.target.value * 100;
    },
    initUpdateForm(row) {
      if (row) {
        Object.keys(this.updateForm).forEach((key) => {
          this.updateForm[key].qualifies = true;
          if (
            this.updateForm[key].qualifier &&
            !this.updateForm[key].qualifier(row, this)
          ) {
            this.updateForm[key].qualifies = false;
          }
          if (this.updateForm[key].type === "autocomplete-select") {
            const keys = key.split(".");
            if (keys.length === 2) {
              this.updateForm[key].value = row[keys[0]][keys[1]];
            } else {
              this.updateForm[key].value = row[key];
            }
            if (this.updateForm[key].autocompleteEndpointFunction) {
              this.updateForm[key].autocompleteEndpoint =
                this.updateForm[key].autocompleteEndpointFunction(row);
            }
            const idKeys = this.updateForm[key].idKey.split(".");
            const labelKeys = this.updateForm[key].labelKey.split(".");
            if (idKeys.length === 2) {
              this.updateForm[key].value = {
                id: row[key][idKeys[0]][idKeys[1]],
                label: row[key][labelKeys[0]][labelKeys[1]],
              };
            } else if (row[key]) {
              let label;
              if (this.updateForm[key].labelFunction) {
                label = this.updateForm[key].labelFunction(row[key]);
              } else {
                label = row[key][labelKeys[0]];
              }
              this.updateForm[key].value = {
                id: row[key][idKeys[0]],
                label,
              };
            } else if (keys.length === 2 && row[keys[0]][keys[1]]) {
              let label;
              if (this.updateForm[key].labelFunction) {
                label = this.updateForm[key].labelFunction(
                  row[keys[0]][keys[1]],
                );
              } else {
                label = row[keys[0]][keys[1]][labelKeys[0]];
              }
              this.updateForm[key].value = {
                id: row[keys[0]][keys[1]][idKeys[0]],
                label,
              };
            } else {
              this.updateForm[key].value = null;
            }
            if (this.updateForm[key].filterFunction) {
              this.updateForm[key].filters =
                this.updateForm[key].filterFunction(row);
            }
          } else if (this.updateForm[key].type === "autocomplete-multiselect") {
            if (this.updateForm[key].autocompleteEndpointFunction) {
              this.updateForm[key].autocompleteEndpoint =
                this.updateForm[key].autocompleteEndpointFunction(row);
            }
            const idKeys = this.updateForm[key].idKey.split(".");
            const labelKeys = this.updateForm[key].labelKey.split(".");
            if (idKeys.length === 2) {
              this.updateForm[key].value = row[key].map((i) => ({
                id: i[idKeys[0]][idKeys[1]],
                label: i[labelKeys[0]][labelKeys[1]],
              }));
            } else {
              this.updateForm[key].value = row[key].map((i) => ({
                id: i[idKeys[0]],
                label: i[labelKeys[0]],
              }));
            }
          } else if (this.updateForm[key].type === "select") {
            this.updateForm[key].value = {
              id: row[key],
              label: row[key],
            };
          } else if (this.updateForm[key].type === "file") {
            let rowVal = row[snakeToCamel(key)];
            if (!Array.isArray(rowVal)) {
              rowVal = rowVal.hashedId ? [rowVal] : [];
            }
            this.updateForm[key].value = rowVal.map((i) => ({
              id: i.hashedId,
              name: i.name,
              type: "image",
            }));
          } else if (this.updateForm[key].type === "textlines") {
            const { labelKey } = this.updateForm[key];
            const lines = row[key].map((i) => i[labelKey]);
            this.updateForm[key].value = lines.join("\n");
          } else if (this.updateForm[key].type === "dataTable") {
            this.updateForm[key].parentRow = row;
          } else if (this.updateForm[key].type === "revisionHistory") {
            this.updateForm[key].parentRow = row;
          } else if (this.updateForm[key].type === "parentDataTable") {
            this.updateForm[key].parentRow = row;
          } else if (
            this.updateForm[key].type === "datetime" &&
            this.updateForm[key].momentFormat
          ) {
            this.updateForm[key].value = this.moment(row[key]).format(
              this.updateForm[key].momentFormat,
            );
          } else if (this.updateForm[key].type === "markdown") {
            this.updateForm[key].value = row[key] || "";
          } else {
            const keys = key.split(".");
            if (keys.length === 2) {
              this.updateForm[key].value = row[keys[0]][keys[1]];
            } else {
              this.updateForm[key].value = row[key];
            }
          }
        });
      } else {
        Object.keys(this.updateForm).forEach((key) => {
          this.updateForm[key].value = this.updateForm[key].default;
        });
      }
    },
    onUpdateSubmit(evt) {
      evt.preventDefault();
      const submission = {};
      let hasErrors = false;
      Object.keys(this.updateForm).forEach((key) => {
        if (
          !this.validateInput(this.updateForm[key], this.updateForm[key].value)
        ) {
          hasErrors = true;
        }
        submission[key] = this.updateForm[key].value;
      });
      const payload = keysToSnake(submission, true);
      Object.keys(payload).forEach((key) => {
        if (
          typeof payload[key] === "object" &&
          payload[key] &&
          Object.keys(payload[key]).length === 2 &&
          Object.keys(payload[key]).indexOf("id") > -1
        ) {
          payload[key] = payload[key].id;
        }
      });
      if (!hasErrors) {
        this.updateRow(this.updateForm.hashedId.value, payload);
      } else {
        this.$toast.warning(
          "The form has one or more errors. Please see the validation messages under each field for more information.",
        );
      }
    },
    onUpdateReset(evt) {
      evt.preventDefault();
      this.toggleModal(`updateRowSidebar`);
      this.initUpdateForm(null);
    },
    invokeSort(key) {
      if (Object.keys(this.tableSortKeys).indexOf(key) === -1) {
        this.dataTable.sort.push({ [key]: "asc" });
        this.getData();
      } else {
        this.dataTable.sort.forEach((val, index) => {
          if (Object.keys(val)[0] === key) {
            if (val[key] === "asc") {
              this.dataTable.sort[index][key] = "desc";
            } else if (val[key] === "desc") {
              this.dataTable.sort.splice(index, 1);
            }
          }
        });
        this.getData();
      }
    },
    invokeFilter(key) {
      if (
        Object.keys(this.dataTable.filters).indexOf(key) > -1 &&
        this.dataTable.filters[key].length === 0
      ) {
        delete this.dataTable.filters[key];
      }

      if (this.filterTimeout) {
        clearTimeout(this.filterTimeout);
      }
      this.filterTimeout = setTimeout(this.getData, 500);
    },
    clearFilter(filters, name) {
      delete filters[name];
      this.getData();
    },
    validateInput(metadata, value) {
      if (
        typeof metadata.validatorTypes === "undefined" ||
        metadata.validatorTypes.length === 0
      ) {
        return true;
      }
      metadata.errors = [];
      if (metadata.validatorTypes.indexOf("required") > -1) {
        if (
          metadata.type === "decimalTimesOneHundred" &&
          typeof value === "undefined"
        ) {
          metadata.errors.push("A value is required.");
        } else if (value === null || value.length === 0) {
          metadata.errors.push("A value is required.");
        }
      }
      if (metadata.validatorTypes.indexOf("slug") > -1) {
        const result = slugValidator(value);
        // position 0 means it matches the full string
        if (result == null || result.index > 0) {
          metadata.errors.push(
            "Slugs can only contain letters, numbers, and dashes and cannot begin or end with a dash.",
          );
        }
      }
      if (metadata.validatorTypes.indexOf("email") > -1) {
        const result = emailValidator(value);
        // position 0 means it matches the full string
        if (result == null || result.index > 0) {
          metadata.errors.push("Please enter a valid email address.");
        }
      }
      return metadata.errors.length === 0;
    },
    fieldLabel(metadata) {
      if (
        typeof metadata.validatorTypes !== "undefined" &&
        metadata.validatorTypes.indexOf("required") > -1
      ) {
        return `${metadata.label}*`;
      }
      return metadata.label;
    },
    compiledTemplate(html) {
      return compile(html);
    },
  },
};
</script>
