<template>
  <div class="bg-white rounded-lg shadow-lg mx-5 my-5 px-5 py-3">
    <div class="flex justify-between">
      <div class="w-full md:w-3/4 pl-5">
        <div class="absolute -ml-5">
          <button @click="toggleExpanded()">
            <i
              :class="{ 'rotate-90': expanded }"
              class="text-lg fa-solid fa-caret-right"
            ></i>
          </button>
        </div>
        <button @click="toggleExpanded()">
          <h1 class="text-2xl">{{ collaborationSpace.name }}</h1>
        </button>
        <h4 v-if="collaborationSpace.organizationName" class="text">
          {{ collaborationSpace.organizationName
          }}<span v-if="collaborationSpace.organizationStatus">
            • {{ collaborationSpace.organizationStatus[0] }}</span
          >
        </h4>
        <div
          v-if="!expanded && (hasUnmappedDebits || hasOrphanInvoiceLineItems)"
          class="inline-block px-2 pb-1 pt-[5px] bg-orange-200 border border-orange-400 text-black/50 text-sm rounded"
        >
          <p v-if="hasUnmappedDebits">
            <i class="fa-solid fa-triangle-exclamation"></i> Unmapped debits.
          </p>
          <p v-if="hasOrphanInvoiceLineItems">
            <i class="fa-solid fa-triangle-exclamation"></i> Unconnected invoice
            line items.
          </p>
        </div>
        <div
          v-else-if="!expanded"
          class="inline-block px-2 pb-1 pt-[5px] bg-green-200 border border-green-400 text-black/50 text-sm rounded"
        >
          <p><i class="fa-solid fa-check"></i> Looks good.</p>
        </div>
      </div>
      <div class="w-full md:w-1/4">
        <div class="flex w-full justify-between">
          <div>
            <span class="text-sm uppercase font-bold">Credit Balance:</span>
          </div>
          <div>
            <span class="text-xs"
              >{{ collaborationSpace.totalCreditsPurchased }} purchased -
              {{ collaborationSpace.totalCreditsConsumed }} consumed = </span
            >{{ collaborationSpace.remainingCreditBalance }}
          </div>
        </div>
        <div class="flex w-full justify-between">
          <div>
            <span class="text-sm uppercase font-bold"
              >Total Amount Collected:</span
            >
          </div>
          <div>
            {{ USDollar.format(collaborationSpace.totalAmountCollected) }}
          </div>
        </div>
        <div class="flex w-full justify-between">
          <div>
            <span class="text-sm uppercase font-bold"
              >Total Fulfillment Cost:</span
            >
          </div>
          <div>
            {{ USDollar.format(collaborationSpace.totalFulfillmentCost) }}
          </div>
        </div>
      </div>
    </div>
    <template v-if="expanded">
      <div v-if="collaborationSpace.creditTransactions.length > 0" class="mt-3">
        <table class="border border-2 w-full">
          <thead class="bg-porcelain">
            <tr>
              <th class="uppercase text-xs text-left border px-2 py-1">Date</th>
              <th class="uppercase text-xs text-left border px-2 py-1">Type</th>
              <th class="uppercase text-xs text-left border px-2 py-1">
                $ / Credit
              </th>
              <th class="uppercase text-xs text-left border px-2 py-1">Qty</th>
              <th class="uppercase text-xs text-left border px-2 py-1">
                Detail
              </th>
              <th class="uppercase text-xs text-left border px-2 py-1">
                Balance
              </th>
            </tr>
          </thead>
          <tbody>
            <finance-transaction-row
              v-for="transaction in collaborationSpace.creditTransactions"
              :key="transaction.hashedId"
              :transaction="transaction"
            />
          </tbody>
        </table>
      </div>
      <div
        v-if="collaborationSpace.orphanInvoiceLineItems.length > 0"
        class="bg-orange-100 px-3 py-2 mt-3"
      >
        The following invoice line items are connected to projects in this
        Collaboration Space but are
        <strong>not tied to a credit transaction</strong> and could not be
        included in the credit consumption calculations. To connect them, go to
        the invoice in Admin, select the line item detail, and choose the
        correct credit transaction.
        <table
          class="border border-2 border-orange-400 bg-orange-200 w-full mt-2"
        >
          <thead>
            <tr>
              <th
                class="uppercase text-xs text-left border border-orange-400 px-2 py-1 w-[80px]"
              >
                Date
              </th>
              <th
                class="uppercase text-xs text-left border border-orange-400 px-2 py-1 w-[100px]"
              >
                Invoice Status
              </th>
              <th
                class="uppercase text-xs text-left border border-orange-400 px-2 py-1 w-[80px]"
              >
                Amount
              </th>
              <th
                class="uppercase text-xs text-left border border-orange-400 px-2 py-1 w-[160px]"
              >
                Person
              </th>
              <th
                class="uppercase text-xs text-left border border-orange-400 px-2 py-1"
              >
                Detail
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="lineItem in collaborationSpace.orphanInvoiceLineItems"
              :key="lineItem.hashedId"
            >
              <td class="border border-orange-400 px-2 py-1">
                {{ $dayjs(lineItem.date).format("MM/DD/YYYY") }}
              </td>
              <td class="border border-orange-400 px-2 py-1">
                {{ lineItem.invoiceStatus[0] }}
              </td>
              <td class="border border-orange-400 px-2 py-1">
                {{ USDollar.format(lineItem.amount) }}
              </td>
              <td class="border border-orange-400 px-2 py-1">
                {{ lineItem.personFullName }}
              </td>
              <td class="border border-orange-400 px-2 py-1">
                <p class="uppercase font-bold text-xs text-black/30">Project</p>
                {{ lineItem.workTitle }}
                <p
                  v-if="lineItem.notes"
                  class="uppercase font-bold text-xs text-black/30"
                >
                  Client Notes
                </p>
                {{ lineItem.notes }}
                <p
                  v-if="lineItem.clientNotes"
                  class="uppercase font-bold text-xs text-black/30"
                >
                  Admin Notes
                </p>
                {{ lineItem.adminNotes }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import FinanceTransactionRow from "./TransactionRow.vue";

export default {
  name: "AdminFinanceCollaborationSpace",
  components: {
    FinanceTransactionRow,
  },
  props: {
    collaborationSpace: Object,
  },
  data() {
    return {
      expanded: false,
      USDollar: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }),
    };
  },
  computed: {
    hasUnmappedDebits() {
      let result = false;
      this.collaborationSpace.creditTransactions?.forEach((txn) => {
        if (
          txn.transactionType[0] === "Debit" &&
          !txn.invoiceLineItems.length &&
          txn.nonInvoiceFulfillmentCost === null
        ) {
          result = true;
        }
      });
      return result;
    },
    hasOrphanInvoiceLineItems() {
      return this.collaborationSpace.orphanInvoiceLineItems.length > 0;
    },
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
  },
};
</script>
